import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import './PartnershipStatusFilters.scss';

import { ActionsOverlay } from '../Modals/ActionsOverlay';
import { getBrand } from '../../Helpers/user_helpers';

const PartnershipStatusFilters = props => {
  const { filters, setFilters } = props;
  const { hideWithGifting, hideWithCodes, hideWithRates, hideWithOpportunities } = filters;

  const [isTogglingFilter, setIsTogglingFilter] = React.useState(false);
  const toggleFilter = async filter => {
    if (isTogglingFilter) return window.ALERT.info('Please wait for the previous filter application to complete.');
    setIsTogglingFilter(filter);
    await setFilters({ ...filters, [filter]: !filters[filter] });
    setIsTogglingFilter(null);
  };

  const actionOptions = [
    {
      display: `${hideWithGifting ? 'Hiding' : 'Hide'} Already Gifted`,
      icon: 'faGift',
      filter: 'hideWithGifting'
    },
    {
      display: `${hideWithCodes ? 'Hiding' : 'Hide'} With Codes`,
      icon: 'faTag',
      filter: 'hideWithCodes'
    },
    {
      display: `${hideWithRates ? 'Hiding' : 'Hide'} With Custom Rates`,
      icon: 'faPercent',
      filter: 'hideWithRates'
    },
    {
      display: `${hideWithOpportunities ? 'Hiding' : 'Hide'} With Opportunities`,
      icon: 'faChartLine',
      filter: 'hideWithOpportunities'
    }
  ].map(option => ({
    ...option,
    onClick: () => toggleFilter(option.filter),
    isActive: filters[option.filter],
    highlightOnActive: true,
    isPerformingAction: isTogglingFilter === option.filter
  }));

  const numActiveFilters = _.values(filters).filter(f => f).length;

  return (
    <div className='partnership-status-filters-outer-container'>
      <ActionsOverlay
        openUp
        containerClassName='action-button'
        options={actionOptions}
        onOpen={() => window.__ADD_EVENT__('Analytics - Filter for Partnership Status', { brand: getBrand(props.user) })}
      >
        <div className={cn('filter-btn', { 'has-filters-set': numActiveFilters > 0 })}>
          <FontAwesomeIcon icon={icons[actionOptions.find(option => option.isActive)?.icon || actionOptions[0].icon]} />
          {numActiveFilters > 0 && <div className='count-label'>{numActiveFilters}</div>}
        </div>
      </ActionsOverlay>
    </div>
  );
};

PartnershipStatusFilters.propTypes = {
  // From inside
  user: PropTypes.object.isRequired,

  // From outside
  filters: PropTypes.shape({
    hideWithGifting: PropTypes.bool,
    hideWithCodes: PropTypes.bool,
    hideWithRates: PropTypes.bool,
    hideWithOpportunities: PropTypes.bool
  }).isRequired,
  setFilters: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  // functions go here
})(PartnershipStatusFilters);
