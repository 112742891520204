import React from 'react';
import Switch from 'react-switch';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import _ from 'lodash';
import cn from 'classnames';
import './DiscoverControls.scss';

import Select from '../General/Select';

import { getDiscoverSortOptions } from '../../Helpers/discover_helpers';
import { blockOnRequiringSubscription } from '../../Helpers/subscription_helpers';

const DiscoverControls = props => {
  const { user, selectedTags, setSelectedTags, sortBy, setSortBy } = props;

  const groupedSortOptions = getDiscoverSortOptions();

  const toggleOnlyMyPromoters = () => {
    props.checkSubscriptionLevelThenRunFn(() => {
      props.setOnlyMyPromoters(!props.onlyMyPromoters);
      props.setHideMyPromoters(false);
      props.setPage(0);
      window.__ADD_EVENT__(props.onlyMyPromoters ? 'Discover - Unselect Only My Promoters' : 'Discover - Select Only My Promoters');
    });
  };
  const toggleHideMyPromoters = () => {
    props.checkSubscriptionLevelThenRunFn(() => {
      props.setHideMyPromoters(!props.hideMyPromoters);
      props.setOnlyMyPromoters(false);
      props.setPage(0);
      window.__ADD_EVENT__(props.hideMyPromoters ? 'Discover - Hide My Promoters' : 'Discover - Show My Promoters', { user });
    });
  };
  const toggleHideAlreadyGifted = () => {
    props.checkSubscriptionLevelThenRunFn(() => {
      props.setHideAlreadyGifted(!props.hideAlreadyGifted);
      props.setPage(0);
      window.__ADD_EVENT__(props.hideAlreadyGifted ? 'Discover - Hide Already Gifted' : 'Discover - Show Already Gifted', { user });
    });
  };

  const changeSort = value => {
    props.checkSubscriptionLevelThenRunFn(() => {
      const fullOption = _.flatten(_.map(groupedSortOptions, 'options')).find(o => o.value === value);
      if (fullOption.requiredFeature && blockOnRequiringSubscription(user, fullOption.requiredFeature)) return null;
      props.setPage(0);
      setSortBy(value);
      window.__ADD_EVENT__('Discover - Sort By', { Sort_by: fullOption.label });
    });
  };

  return (
    <div className='discover-controls-container'>
      <div className='main'>
        <div className='selected-tags'>
          {selectedTags.map(tag => {
            const clearTag = () => {
              props.setPage(0);
              const newSelectedTags = selectedTags.filter(t => t.id !== tag.id);
              setSelectedTags(newSelectedTags);
              window.__ADD_EVENT__('Discover - Unselected Tag', {
                Tag_value: tag.value,
                Tag_type: tag.type,
                All_selected_tags: newSelectedTags.map(t => t.value).join(', '),
                Source: 'ControlBar' // Since you can also unselect from the top bar
              });
            };
            return (
              <div onClick={clearTag} className='selected-tag' key={tag.id}>
                {tag.value}
                <FontAwesomeIcon icon={faTimes} />
              </div>
            );
          })}
          {props.onlyMyPromoters && (
            <div onClick={toggleOnlyMyPromoters} className='selected-tag'>
              Only My Promoters
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
        </div>
      </div>
      <div className='secondary'>
        <div className='radio-toggle control'>
          <div className={cn('radio-toggle-label label', { active: props.hideAlreadyGifted })}>Hide Already Gifted</div>
          <Switch
            onChange={toggleHideAlreadyGifted}
            checked={props.hideAlreadyGifted}
            height={14}
            width={20}
            onColor='#11835a'
            offColor='#777'
            offHandleColor='#999'
            checkedIcon={false}
            uncheckedIcon={false}
          />
        </div>
        <div className='toggle-set'>
          <div className='radio-toggle control'>
            <div className={cn('radio-toggle-label label', { active: props.onlyMyPromoters })}>Only My Promoters</div>
            <Switch
              onChange={toggleOnlyMyPromoters}
              checked={props.onlyMyPromoters}
              height={14}
              width={20}
              onColor='#11835a'
              offColor='#777'
              offHandleColor='#999'
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
          <div className='radio-toggle control'>
            <div className={cn('radio-toggle-label label', { active: props.hideMyPromoters })}>Hide My Promoters</div>
            <Switch
              onChange={toggleHideMyPromoters}
              checked={props.hideMyPromoters}
              height={14}
              width={20}
              onColor='#11835a'
              offColor='#777'
              offHandleColor='#999'
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
        </div>
        <div className='select control'>
          <div className='select-label label active'>Sort By</div>
          <div className='select-container'>
            <Select value={sortBy} options={groupedSortOptions} onChangeValue={changeSort} />
          </div>
        </div>
      </div>
    </div>
  );
};

DiscoverControls.propTypes = {
  user: PropTypes.object.isRequired,
  selectedTags: PropTypes.array.isRequired,
  setSelectedTags: PropTypes.func.isRequired,
  onlyMyPromoters: PropTypes.bool.isRequired,
  setOnlyMyPromoters: PropTypes.func.isRequired,
  hideMyPromoters: PropTypes.bool.isRequired,
  setHideMyPromoters: PropTypes.func.isRequired,
  hideAlreadyGifted: PropTypes.bool.isRequired,
  setHideAlreadyGifted: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  setSortBy: PropTypes.func.isRequired,
  checkSubscriptionLevelThenRunFn: PropTypes.func.isRequired
};

export default DiscoverControls;
