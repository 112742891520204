import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './ChatSort.scss';

const ChatSort = props => {
  /* Currently only a brand feature. */
  const { activeSortFnObject } = props;
  const sortOptions = [
    { display: 'Newest', sortFn: null }, // This is the default sort so set to null
    { display: 'Creator Score', sortFn: c => c.user.tier.score, sortFnDirection: 'desc' },
    { display: 'Creator Score (Low to High)', sortFn: c => c.user.tier.score, sortFnDirection: 'asc' }
  ];

  useEffect(() => {
    window.__ADD_EVENT__('Chat - Sort');
  }, []);

  return (
    <div className='chat-sort-outer-container'>
      <div className='panel-header'>
        <div className='title'>Sort By</div>
        <div onClick={props.closePanel} className='close-btn'>
          Done
        </div>
      </div>
      <div className='chat-sort-inner-container'>
        <div className='sort-options'>
          {sortOptions.map((option, idx) => {
            const isRootOption = option.sortFn === null;
            const isActive = activeSortFnObject ? activeSortFnObject.display === option.display : isRootOption;
            const select = () => {
              props.setSortFnObject(isRootOption ? null : option);
              props.closePanel();
            };
            return (
              <div onClick={select} key={idx} className={cn('sort-option', { active: isActive })}>
                <div className='title'>Sort by {option.display}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

ChatSort.propTypes = {
  // From outside
  activeSortFnObject: PropTypes.object,
  setSortFnObject: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,

  // From inside
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  // functions go here
})(ChatSort);
