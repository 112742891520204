import _ from 'lodash';

import { fetchBrandAnalytics } from '../APIClient/analytics';
import {
  setCommissionRate,
  setCode,
  sendSamplesRequest,
  deleteSamplesRequest,
  updateRequest as updateRequestAPI,
  updateCode as updateCodeAPI
} from '../APIClient/brands';
import {
  createOpportunityRequest as createOpportunityRequestAPI,
  updateOpportunityRequest as updateOpportunityRequestAPI,
  deleteOpportunityRequest as deleteOpportunityRequestAPI
} from '../APIClient/requests';
import { getBrandId } from '../Helpers/user_helpers';

export const GET_BRAND_ANALYTICS_REQUEST = 'GET_BRAND_ANALYTICS_REQUEST';
export const GET_BRAND_ANALYTICS_SUCCESS = 'GET_BRAND_ANALYTICS_SUCCESS';
export const GET_BRAND_ANALYTICS_FAILURE = 'GET_BRAND_ANALYTICS_FAILURE';
export const GET_USER_ANALYTICS_SUCCESS = 'GET_USER_ANALYTICS_SUCCESS';
export const GET_USER_ANALYTICS_FAILURE = 'GET_USER_ANALYTICS_FAILURE';

export const SET_CUSTOM_COMMISSION_RATE_SUCCESS = 'SET_CUSTOM_COMMISSION_RATE_SUCCESS';
export const SET_CUSTOM_COMMISSION_RATE_FAILURE = 'SET_CUSTOM_COMMISSION_RATE_FAILURE';

export const SET_CUSTOM_CODE_SUCCESS = 'SET_CUSTOM_CODE_SUCCESS';
export const SET_CUSTOM_CODE_FAILURE = 'SET_CUSTOM_CODE_FAILURE';

export const CREATE_SAMPLES_REQUEST_SUCCESS = 'CREATE_SAMPLES_REQUEST_SUCCESS';
export const CREATE_SAMPLES_REQUEST_FAILURE = 'CREATE_SAMPLES_REQUEST_FAILURE';
export const REMOVE_SAMPLES_REQUEST_SUCCESS = 'REMOVE_SAMPLES_REQUEST_SUCCESS';
export const REMOVE_SAMPLES_REQUEST_FAILURE = 'REMOVE_SAMPLES_REQUEST_FAILURE';

export const CREATE_OPPORTUNITY_REQUEST_SUCCESS = 'CREATE_OPPORTUNITY_REQUEST_SUCCESS';
export const CREATE_OPPORTUNITY_REQUEST_FAILURE = 'CREATE_OPPORTUNITY_REQUEST_FAILURE';
export const UPDATE_OPPORTUNITY_REQUEST_REQUEST = 'UPDATE_OPPORTUNITY_REQUEST_REQUEST';
export const UPDATE_OPPORTUNITY_REQUEST_SUCCESS = 'UPDATE_OPPORTUNITY_REQUEST_SUCCESS';
export const UPDATE_OPPORTUNITY_REQUEST_FAILURE = 'UPDATE_OPPORTUNITY_REQUEST_FAILURE';
export const REMOVE_OPPORTUNITY_REQUEST_REQUEST = 'REMOVE_OPPORTUNITY_REQUEST_REQUEST';
export const REMOVE_OPPORTUNITY_REQUEST_SUCCESS = 'REMOVE_OPPORTUNITY_REQUEST_SUCCESS';
export const REMOVE_OPPORTUNITY_REQUEST_FAILURE = 'REMOVE_OPPORTUNITY_REQUEST_FAILURE';

export const getBrandAnalytics = () => async (dispatch, getState) => {
  const { user } = getState();
  dispatch({ type: GET_BRAND_ANALYTICS_REQUEST });
  try {
    const resp = await fetchBrandAnalytics(user);
    return dispatch({
      type: GET_BRAND_ANALYTICS_SUCCESS,
      analytics: _.get(resp, 'analytics', {})
    });
  } catch (error) {
    return dispatch({
      type: GET_BRAND_ANALYTICS_FAILURE
    });
  }
};

export const setCustomCommissionRate = ({ User_id, rate, rate_returning, brandMessage, filesToUpload }) => async (dispatch, getState) => {
  const { user } = getState();
  try {
    const resp = await setCommissionRate({ user, User_id, rate, rate_returning, brandMessage, filesToUpload });
    return dispatch({
      type: SET_CUSTOM_COMMISSION_RATE_SUCCESS,
      customRates: _.get(resp, 'customRates', {})
    });
  } catch (error) {
    return dispatch({
      type: SET_CUSTOM_COMMISSION_RATE_FAILURE,
      error: _.get(error, 'name', error)
    });
  }
};

export const setCustomCode = (userid, data) => async (dispatch, getState) => {
  const { user } = getState();
  try {
    const { id = null, rateDisplay, displayText, brandMessage, shopifyCodeType, shopifyCollectionIds, brandIntegrationIds } = data;
    const rate = parseFloat(rateDisplay.replace('$', '').replace('%', ''));
    const isPercentage = !rateDisplay.includes('$');

    const resp = await setCode({
      id,
      user,
      userid,
      rate,
      isPercentage,
      displayText,
      brandMessage,
      shopifyCodeType,
      shopifyCollectionIds,
      brandIntegrationIds
    });

    if (rate) window.__ADD_EVENT__('Talent Card - Offer Discount Code', { code: data });
    else window.__ADD_EVENT__('Talent Card - Remove Discount Code', { code: data });
    return dispatch({
      type: SET_CUSTOM_CODE_SUCCESS,
      customCodes: _.get(resp, 'customCodes', {})
    });
  } catch (error) {
    dispatch({
      type: SET_CUSTOM_CODE_FAILURE,
      error: _.get(error, 'name', error)
    });
    throw error;
  }
};

export const createSamplesRequest = (artist, extra) => async (dispatch, getState) => {
  const { user } = getState();
  try {
    const resp = await sendSamplesRequest(getBrandId(user), artist, extra);
    return dispatch({
      type: CREATE_SAMPLES_REQUEST_SUCCESS,
      request: resp.request
    });
  } catch (error) {
    return dispatch({
      type: CREATE_SAMPLES_REQUEST_FAILURE,
      error: _.get(error, 'error', error)
    });
  }
};

export const removeSamplesRequest = request => async (dispatch, getState) => {
  try {
    await deleteSamplesRequest(request);
    return dispatch({
      type: REMOVE_SAMPLES_REQUEST_SUCCESS,
      request
    });
  } catch (error) {
    return dispatch({
      type: REMOVE_SAMPLES_REQUEST_FAILURE,
      error: _.get(error, 'error', error)
    });
  }
};

export const updateCode = (code, updates) => async (dispatch, getState) => {
  /*
    We want to move this out of analytics and put it into the user object under brand_codes which
    we can handle as we did with brand_requests.
  */
  try {
    await updateCodeAPI(code, updates);
    return dispatch(getBrandAnalytics());
  } catch (error) {
    console.error(error);
  }
};

export const updateRequest = (request, updates) => async (dispatch, getState) => {
  /*
    !! Only use this if you are updating a request from the brand side  !!

    At some point if this gets too complicated we need to pull the brand requests out of the analytics object, but
    for now we will just reload analytics upon any updates to sub-fields and use the updateUserRequest from Users.js
  */
  try {
    await updateRequestAPI(request, updates);
    return dispatch(getBrandAnalytics());
  } catch (error) {
    console.error(error);
  }
};

export const createOpportunityRequest = opportunity => async (dispatch, getState) => {
  try {
    const newRequest = await createOpportunityRequestAPI(opportunity);
    return dispatch({
      type: CREATE_OPPORTUNITY_REQUEST_SUCCESS,
      request: newRequest
    });
  } catch (error) {
    return dispatch({
      type: CREATE_OPPORTUNITY_REQUEST_FAILURE,
      error: _.get(error, 'error', error)
    });
  }
};

export const updateOpportunityRequest = (request, updates) => async (dispatch, getState) => {
  // Use this only for the brand updating requests
  try {
    dispatch({ type: UPDATE_OPPORTUNITY_REQUEST_REQUEST, request, updates });
    const newRequest = await updateOpportunityRequestAPI(request, updates);
    return dispatch({
      type: UPDATE_OPPORTUNITY_REQUEST_SUCCESS,
      request: newRequest
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_OPPORTUNITY_REQUEST_FAILURE,
      request,
      error: _.get(error, 'error', error)
    });
  }
};

export const removeOpportunityRequest = request => async (dispatch, getState) => {
  try {
    dispatch({
      type: REMOVE_OPPORTUNITY_REQUEST_REQUEST,
      request
    });
    await deleteOpportunityRequestAPI(request);
    return dispatch({
      type: REMOVE_OPPORTUNITY_REQUEST_SUCCESS,
      request
    });
  } catch (error) {
    return dispatch({
      type: REMOVE_OPPORTUNITY_REQUEST_FAILURE,
      error: _.get(error, 'error', error)
    });
  }
};
