import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import { confirmAlert } from 'react-confirm-alert';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import './Mentions.scss';

import { openArtistModal, openChatOverlay, openAuthModal } from '../../Actions/UIActions';
import { updateMention } from '../../APIClient/mentions';

import { getBrandId, isBrand } from '../../Helpers/user_helpers';
import { getAnnouncementByType } from '../../Helpers/announcement_helpers';
import { blockOnRequiringSubscription } from '../../Helpers/subscription_helpers';
import { insertMetaTags } from '../../Helpers/seo_helpers';

import MentionsData from '../../Components/Mentions/MentionsData';
import MentionControls from '../../Components/Mentions/MentionControls';
import MentionFilters from '../../Components/Mentions/MentionFilters';
import MentionResults from '../../Components/Mentions/MentionResults';
import EditMentionTagsModal from '../../Components/Mentions/Elements/EditMentionTagsModal';
import ScrollToTop from '../../Components/General/ScrollToTop';

import AnnouncementModal from '../../Components/Announcements/AnnouncementModal';
import RequiresBrandLoginPanel from '../../Components/General/RequiresBrandLoginPanel';

const Mentions = props => {
  const { ui, user, analytics, openArtistModal, openChatOverlay } = props;

  useEffect(() => {
    window.__ADD_EVENT__('Mentions - View Page');
  }, []);

  // if the url has .../mentions?Lookbook_id=123 or any other query params, we will use that in initial filters
  // Lookbook_id, User_id, BrandList_id, subtype, platform
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const initialFilters = _.omitBy(
    {
      Lookbook_id: parseInt(urlParams.get('Lookbook_id')) || null,
      User_id: parseInt(urlParams.get('User_id')) || null,
      BrandList_id: parseInt(urlParams.get('BrandList_id')) || null,
      subtype: urlParams.get('subtype'),
      platform: urlParams.get('platform')
    },
    _.isNil
  );

  // Data
  const [mentions, setMentions] = useState([]);
  const [isFetchingMentions, setIsFetchingMentions] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(true);
  const [, setErrorMsg] = useState(null); // Not currently used
  const [curFilters, setCurFilters] = useState({ Brand_id: getBrandId(user), page: 0, sortBy: 'contentPublishedAt', ...initialFilters });
  const [filterCounts, setFilterCounts] = useState({});
  const [summary, setSummary] = useState(null);
  const [sortOptions, setSortOptions] = useState([]);
  const [brandAndTargetOptions, setBrandAndTargetOptions] = useState({});
  const isFetchingFirstPage = !curFilters.page && isFetchingMentions;

  // Subscription Handling
  const blockUsage = () => blockOnRequiringSubscription(user, 'SOCIAL_MENTIONS_FEED', { ui });
  const announcement = getAnnouncementByType(user, 'SOCIAL_MENTIONS_FEED');

  // Downloading Results
  const [isDownloading, setIsDownloading] = useState(false);

  // UI
  const [isEditingTags, setIsEditingTags] = useState(false);
  const toggleEditingTags = options => {
    const { needsRefresh } = options || {};
    if (needsRefresh) setCurFilters({ ...curFilters, page: 0 }); // Force a reload
    setIsEditingTags(!isEditingTags);
  };

  // Helpers
  const getNextPage = () => {
    !isFetchingMentions &&
      hasMoreResults &&
      setCurFilters({
        ...curFilters,
        page: curFilters.page + 1
      });
  };

  const removeMentionIfAllowedTo = async mention => {
    const isAffiliateLinkOnly = !!mention.QuickLinkPin_id && !mention.Collection_id && !mention.tagFound;
    confirmAlert({
      title: 'Are you sure?',
      message: isAffiliateLinkOnly
        ? `Are you sure you want to remove this mention? Due to limitations with Instagram reporting, we are required to make an informed guess on which story panel was the mention of your brand. It is likely that your brand was mentioned, but this is the wrong panel. We recommend you leave this to ensure the aggregate stats are accurate.`
        : `Are you sure you want to remove this mention?`,
      buttons: [
        {
          label: 'Cancel',
          className: 'cancel',
          onClick: () => null
        },
        {
          label: 'Yes, Remove Mention',
          onClick: async () => {
            await updateMention(mention, { isHidden: true });
            cogoToast.success('Mention Removed');
            setCurFilters({ ...curFilters });
          }
        }
      ]
    });
  };

  // Ensure logged in
  if (!isBrand(user)) return <RequiresBrandLoginPanel />;
  return (
    <div className='mentions-outer-container'>
      {insertMetaTags({
        title: 'Social Mentions',
        description: '',
        image: ''
      })}
      <ScrollToTop />
      <MentionsData
        ui={ui}
        mentions={mentions}
        curFilters={curFilters}
        isFetchingMentions={isFetchingMentions}
        setMentions={setMentions}
        setErrorMsg={setErrorMsg}
        hasMoreResults={hasMoreResults}
        setIsFetchingMentions={setIsFetchingMentions}
        setHasMoreResults={setHasMoreResults}
        setFilterCounts={setFilterCounts}
        setSortOptions={setSortOptions}
        setBrandAndTargetOptions={setBrandAndTargetOptions}
        setSummary={setSummary}
        setIsDownloading={setIsDownloading}
        isDownloading={isDownloading}
      />
      <div className='mentions-inner-container'>
        <div className='sidebar-container'>
          <MentionFilters
            user={user}
            ui={ui}
            mentions={mentions}
            summary={summary}
            blockUsage={blockUsage}
            curFilters={curFilters}
            setCurFilters={setCurFilters}
            filterCounts={filterCounts}
            setFilterCounts={setFilterCounts}
            isFetchingFirstPage={isFetchingFirstPage}
            isFetchingMentions={isFetchingMentions}
            brandAndTargetOptions={brandAndTargetOptions}
            toggleEditingTags={toggleEditingTags}
          />
        </div>
        <div className='main-container'>
          <MentionControls
            user={user}
            ui={ui}
            curFilters={curFilters}
            setCurFilters={setCurFilters}
            sortOptions={sortOptions}
            blockUsage={blockUsage}
            isDownloading={isDownloading}
            setIsDownloading={setIsDownloading}
          />
          <MentionResults
            user={user}
            ui={ui}
            analytics={analytics}
            mentions={mentions}
            curFilters={curFilters}
            isFetchingFirstPage={isFetchingFirstPage}
            isFetchingMentions={isFetchingMentions}
            openArtistModal={openArtistModal}
            openChatOverlay={openChatOverlay}
            openAuthModal={props.openAuthModal}
            getNextPage={getNextPage}
            removeMentionIfAllowedTo={removeMentionIfAllowedTo}
          />
        </div>
      </div>
      {isEditingTags && (
        <EditMentionTagsModal
          close={toggleEditingTags}
          filterCounts={filterCounts}
          brandAndTargetOptions={brandAndTargetOptions}
          curFilters={curFilters}
        />
      )}
      {announcement && !isFetchingMentions && <AnnouncementModal announcement={announcement} extra={{ filterCounts, summary }} />}
    </div>
  );
};

Mentions.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  openAuthModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui, analytics } = state;
  return { user, ui, analytics };
};

export default connect(mapStateToProps, {
  openArtistModal,
  openChatOverlay,
  openAuthModal
})(Mentions);
