import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faDollarSign, faChartLine, faChevronLeft, faComment, faShoppingBag, faBadgeDollar } from '@fortawesome/pro-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';
import './ArtistModal.scss';

import BrandListsOverlay from '../General/BrandListsOverlay';
import Loader from '../Loader/Loader';
import ArtistModalControls from './ArtistModalControls';
import ArtistModalSidebarSocialAccount from './Elements/ArtistModalSidebarSocialAccount';

import { getSmartArtistBio, getShopUrlFromUser, getSocialLinksArray } from '../../Helpers/user_helpers';
import { getInitialsForUser, getFirstName } from '../../Helpers/formatting';
import { getAllBrandListsFoundIn, getGiftingRequestsForUser } from '../../Helpers/brand_helpers';
import { getSmartImage } from '../../Helpers/helpers';
import { getSocialIconsForArtist, getInstagramUsernames, makeInstagramProfileLink } from '../../Helpers/social_helpers';
import { formatUsersForBulkRequests } from '../../Helpers/gifting_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import Tooltip from '../General/Tooltip';

import './ArtistModalSidebar.scss';

const ArtistModalSidebar = props => {
  const { user, analytics, talent, details, isFetchingNewTalent } = props;
  const [isSelectingBrandList, setIsSelectingBrandList] = useState(false);
  const { image, name, social_links, instagramCount, youtubeCount, tiktokCount } = talent || {};
  const { address, social_accounts } = details || {};

  const requests = getGiftingRequestsForUser(analytics, talent);
  const showAddress = !!requests.find(r => r.userAccepted);

  const social_links_array = getSocialLinksArray(talent);
  const ytLink = social_links_array.find(l => l.includes('youtube'));
  const ttLink = social_links_array.find(l => l.includes('tiktok'));

  const isIGConnected = social_accounts.find(a => a.type === 'instagram');
  const isYTConnected = social_accounts.find(a => a.type === 'youtube');
  const isTTConnected = social_accounts.find(a => a.type === 'tiktok');

  const instagramUsernames = getInstagramUsernames({ social_links, social_accounts });
  let instagramAccounts = instagramUsernames.map(username => ({ username, link: makeInstagramProfileLink(username) }));
  instagramAccounts.forEach(account => {
    if (isIGConnected?.instagramUsername?.includes(account.username)) account.count = instagramCount;
  });
  instagramAccounts = instagramAccounts.sort((a, b) => (b.count || 0) - (a.count || 0));

  const [showFullDescription, setShowFullDescription] = useState(false);
  const toggleShowingFullDescription = () => setShowFullDescription(!showFullDescription);

  const offerBonus = () =>
    props.openBonusModal({
      params: { User_id: talent.id, name: talent.name }
    });

  const offerOpportunity = () =>
    props.openRequestModal({
      params: {
        type: 'opportunities',
        preselectedUsers: formatUsersForBulkRequests([talent])
      }
    });

  const inBrandLists = getAllBrandListsFoundIn(user, talent, { hideRecommendationLists: !isAdminControlMode(props.ui) });
  const isInList = !!inBrandLists.length;

  const numTasks = (details?.tasks?.length || 0) + 1; // 1 for custom

  return (
    <div className='artist-modal-sidebar-container'>
      {props.showBackButton && (
        <div className='back-button' onClick={() => props.back()}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
      )}
      <div className={cn('image-container', { 'fetching-new': isFetchingNewTalent })}>
        <Loader size={80} />
        {image ? <img src={getSmartImage(image)} alt={name} /> : <div className='image empty'>{getInitialsForUser(talent)}</div>}
        <div className='social-icons'>{getSocialIconsForArtist(talent, { iconStyle: 'white' })}</div>
        {social_links && <div className='social-icons-fade' />}
        <BrandListsOverlay closeOverlay={() => setIsSelectingBrandList(false)} selectedUser_id={talent.id} isActive={isSelectingBrandList}>
          <div onClick={() => setIsSelectingBrandList(true)} className={cn('select-list-btn', { active: isInList })}>
            {isInList ? <FontAwesomeIcon icon={faHeartSolid} /> : <FontAwesomeIcon icon={faHeart} />}
          </div>
        </BrandListsOverlay>
      </div>
      <div className='data-container'>
        <div>
          <div className='name'>{name}</div>
          <div onClick={toggleShowingFullDescription} className={cn('description', { full: showFullDescription })}>
            {getSmartArtistBio(details)}
          </div>
          <div className='links'>
            <div onClick={() => props.openChatOverlay(talent)} className='link'>
              <FontAwesomeIcon icon={faComment} />
              Chat with {getFirstName(talent.name)}
            </div>
            <div onClick={props.showCollaborations} className='link'>
              <FontAwesomeIcon icon={faBadgeDollar} />
              Collaborate with {getFirstName(talent.name)} {numTasks > 1 ? `(${numTasks})` : ''}
            </div>
            <div onClick={() => window.open(getShopUrlFromUser(talent), '_blank')} className='link'>
              <FontAwesomeIcon icon={faShoppingBag} />
              View Shop
            </div>
            <div onClick={offerBonus} className='link'>
              <FontAwesomeIcon icon={faDollarSign} />
              Send Bonus
            </div>
            <div onClick={offerOpportunity} className='link'>
              <FontAwesomeIcon icon={faChartLine} />
              Send Opportunity
            </div>
          </div>
          {!isFetchingNewTalent && (
            <>
              <ArtistModalControls
                user={user}
                talent={talent}
                analytics={props.analytics}
                createSamplesRequest={props.createSamplesRequest}
                close={props.close}
                setCustomCode={props.setCustomCode}
                setCustomCommissionRate={props.setCustomCommissionRate}
                openCodesModal={props.openCodesModal}
                openRequestModal={props.openRequestModal}
              />
              {showAddress && (
                <div className='address-section section'>
                  <div className='label'>Address:</div>
                  <div className='address'>{address ? address.address : 'Requested'}</div>
                </div>
              )}
              {!!(instagramCount || youtubeCount || tiktokCount) && (
                <div className='social-counts section'>
                  <div className='label'>Social Following:</div>
                  {instagramAccounts?.length ? (
                    <Tooltip
                      message={
                        instagramAccounts.length > 1
                          ? `${talent.name} has two Instagram accounts attached to her account, you will only see social mentions from the connected one`
                          : ''
                      }
                    >
                      <div>
                        {instagramAccounts.map(instagramAccount => (
                          <ArtistModalSidebarSocialAccount
                            key={instagramAccount.username}
                            displayNetworkName='Instagram'
                            displayUsername={`@${instagramAccount.username}`}
                            socialLink={instagramAccount.link}
                            count={instagramAccount.count}
                            isConnected={instagramAccount.count}
                            connectedTooltipMessage={
                              instagramAccounts.length > 1
                                ? ''
                                : `@${instagramAccount.username} is a connected Instagram account, meaning that we are able to track social mentions on Instagram.`
                            }
                            disconnectedTooltipMessage={
                              instagramAccounts.length > 1
                                ? ''
                                : `@${instagramAccount.username} is not a connected Instagram account, meaning that we are unable to track social mentions of your brand unless they explicitly connect one to a collection.`
                            }
                          />
                        ))}
                      </div>
                    </Tooltip>
                  ) : null}
                  {!!youtubeCount && (
                    <ArtistModalSidebarSocialAccount
                      displayNetworkName='Youtube'
                      socialLink={ytLink}
                      count={youtubeCount}
                      isConnected={isYTConnected}
                      connectedTooltipMessage={`${talent.name} has a connected YouTube account, meaning that we are able to track social mentions on YouTube.`}
                      disconnectedTooltipMessage={`${talent.name} does not have a connected Youtube account, meaning that we are unable to track videos that mention your brand unless they explicitly connect one to a collection.`}
                    />
                  )}
                  {!!tiktokCount && (
                    <ArtistModalSidebarSocialAccount
                      displayNetworkName='TikTok'
                      socialLink={ttLink}
                      count={tiktokCount}
                      isConnected={isTTConnected}
                      connectedTooltipMessage={`${talent.name} has a connected TikTok account, meaning that we are able to track videos that mention your brand.`}
                      disconnectedTooltipMessage={`${talent.name} does not have a connected TikTok account, meaning that we are unable to track videos that mention your brand unless they explicitly connect one to a collection.`}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

ArtistModalSidebar.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  back: PropTypes.func.isRequired,
  isFetchingNewTalent: PropTypes.bool.isRequired,
  showBackButton: PropTypes.bool.isRequired,

  // For controls
  createSamplesRequest: PropTypes.func.isRequired,
  openCodesModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  openBonusModal: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  setCustomCode: PropTypes.func.isRequired,
  setCustomCommissionRate: PropTypes.func.isRequired
};

export default ArtistModalSidebar;
