import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import _ from 'lodash';
import cn from 'classnames';
import './BrandBudgetModal.scss';

import { sendBudgetInvoice } from '../../APIClient/budgets';

import { closeBrandBudgetModal } from '../../Actions/UIActions';
import { syncBrandBudget } from '../../Actions/BrandActions';

import { getBrand, getBrandBudgetRemaining, getBrandBudgetPending, getBrandBudgetIncreaseTakeRate } from '../../Helpers/user_helpers';
import { getBrandBudgetModalStartingParams } from '../../Helpers/ui_helpers';

import Modal from '../General/Modal';

const BrandBudgetModal = props => {
  const { user, ui } = props;
  const brand = getBrand(user);
  const params = getBrandBudgetModalStartingParams(ui);
  const amountRequired = params?.amountRequired || 0;
  const SHOPMY_TAKE_RATE = getBrandBudgetIncreaseTakeRate(user);

  /* UI State Management */
  const [newBudgetAmount, setNewBudgetAmount] = React.useState('');
  const [daysUntilDue, setDaysUntilDue] = React.useState(null);
  const [isAddingBudget, setIsAddingBudget] = React.useState(false);
  const [isOnConfirmPanel, setIsOnConfirmPanel] = React.useState(false);
  const [isOnCompletionPanel, setIsOnCompletionPanel] = React.useState(false);

  /* UI Display */
  const budgetRemaining = getBrandBudgetRemaining(user);
  const budgetPending = getBrandBudgetPending(user);
  const onBudgetChange = e => setNewBudgetAmount(e.target.value.replace(/[^0-9.]/g, ''));
  const onDaysUntilDueChange = e => setDaysUntilDue(e.target.value.replace(/[^0-9]/g, ''));
  const backToSetBudget = () => setIsOnConfirmPanel(false);
  const close = () => props.closeBrandBudgetModal();
  const canAddBudget = !!brand.stripeCustomerId;
  const cannotAddBudgetReason = !brand.stripeCustomerId ? 'No Stripe Account Connected' : null;

  /* UI Panel Management */
  const goToConfirmPanel = () => {
    // Confirm that the amount is valid
    const isValidAmount = !_.isNil(newBudgetAmount) && +newBudgetAmount > 0;
    if (!isValidAmount) return window.ALERT.error('Please enter a valid amount to add to your budget.');

    // Send them to the confirmation panel
    setIsOnConfirmPanel(true);
  };

  /* API Management */
  const confirmNewBudgetInvoice = async () => {
    if (isAddingBudget) return window.ALERT.error('Please wait while we process your request.');
    setIsAddingBudget(true);
    try {
      // Send the invoice
      const budgetToAdd = Number(newBudgetAmount);
      const resp = await sendBudgetInvoice(
        _.omitBy(
          {
            Brand_id: brand.id,
            brand_amount: budgetToAdd,
            days_until_due: daysUntilDue || 30,
            sms_amount: (budgetToAdd * SHOPMY_TAKE_RATE) / 100
          },
          _.isNil
        )
      );

      // Sync the budget so they can complete the action initially requested
      await props.syncBrandBudget();

      // Open the invoice in a new tab so they know it was sent
      window.open(resp.stripeInvoice?.hosted_invoice_url, '_blank');

      // Send them to the completion panel to remind them about payment requirements
      setIsOnConfirmPanel(false);
      setIsOnCompletionPanel(true);
    } catch (e) {
      console.error(e);
      window.ALERT.error('There was an error adding funds to your budget. Please try again. If this continues, please contact support.');
    }
    setIsAddingBudget(false);
  };

  /* Data Syncing */
  React.useEffect(() => {
    props.syncBrandBudget();

    // Sync every 2 seconds while this panel is open in order to catch it if the brand makes a payment and returns
    const pollingSync = setInterval(() => document.hasFocus() && props.syncBrandBudget(), 2000);
    return () => clearInterval(pollingSync);
  }, []);

  return (
    <Modal
      visible
      smallForm
      fitContent
      close={close}
      className='brand-budget-modal-outer'
      innerClassName='brand-budget-modal-inner'
      contentClassName='brand-budget-modal-content'
    >
      <div className='modal-header'>
        <div className='header'>
          {isOnCompletionPanel
            ? 'Invoice Sent!'
            : isOnConfirmPanel
            ? 'Final Confirmation'
            : amountRequired
            ? 'Insufficient Funds'
            : 'Add to your budget'}
        </div>
        <div className='subheader'>
          {isOnCompletionPanel
            ? 'Your invoice has been sent. Please pay the invoice in order for the funds to be deposited into your account.'
            : isOnConfirmPanel
            ? 'Are you sure you want to add more funds to your budget?' +
              (window.__ADMIN_CONTROL_MODE__ && daysUntilDue ? ` The invoice will be due in ${daysUntilDue} days.` : '')
            : amountRequired
            ? _.isNil(brand.budget)
              ? `To perform this action you must first upload funds to your account.`
              : `Add more to your budget to continue.`
            : `Please specify how much you would like to add to your budget. Budget can be used for opportunities, bonuses and more.`}
        </div>
      </div>
      <div className='modal-body sections'>
        {isOnCompletionPanel ? (
          <>
            <div className='section'>
              <div className='label'>Budget Invoice Sent:</div>
              <div className='value'>${commaNumber(newBudgetAmount)}</div>
            </div>
            <div className='section'>
              <div className='label'>Pending Payment:</div>
              <div className='value'>${commaNumber(budgetPending)}</div>
            </div>
            <div className='section'>
              <div className='label'>Current Balance:</div>
              <div className='value'>${commaNumber(budgetRemaining)}</div>
            </div>
          </>
        ) : isOnConfirmPanel ? (
          <>
            <div className='section'>
              <div className='label'>Budget To Add:</div>
              <div className='value'>${commaNumber(newBudgetAmount)}</div>
            </div>
            <div className='section'>
              <div className='label'>ShopMy Network Fee:</div>
              <div className='sublabel'>
                ShopMy charges a {SHOPMY_TAKE_RATE}% fee on top of all budget increases to cover miscellaneous costs associated with talent payouts.
              </div>
              <div className='value'>${commaNumber((newBudgetAmount * SHOPMY_TAKE_RATE) / 100)}</div>
            </div>
          </>
        ) : (
          <>
            <div className='section'>
              <div className='label'>Current Balance</div>
              <div className='value'>${commaNumber(budgetRemaining)}</div>
            </div>
            {!!amountRequired && (
              <div className='section'>
                <div className='label'>Amount Required</div>
                <div className='value'>${commaNumber(amountRequired)}</div>
              </div>
            )}
            <div className='section'>
              <div className='label'>New Budget To Add:</div>
              <input
                autoFocus
                placeholder={`${commaNumber(Math.max(amountRequired, 1e4))}`}
                value={commaNumber(newBudgetAmount)}
                onChange={onBudgetChange}
                className='budget-input'
              />
            </div>
            {window.__ADMIN_CONTROL_MODE__ && (
              <div className='section'>
                <div className='label'>Days Until Due:</div>
                <input placeholder={30} value={daysUntilDue} onChange={onDaysUntilDueChange} className='payment-terms-input' />
              </div>
            )}
          </>
        )}
      </div>
      <div className='actions'>
        {isOnCompletionPanel ? (
          <div onClick={props.closeBrandBudgetModal} className='action primary'>
            Done
          </div>
        ) : isOnConfirmPanel ? (
          <>
            <div onClick={backToSetBudget} className='action secondary'>
              Go Back
            </div>
            {canAddBudget ? (
              <div onClick={confirmNewBudgetInvoice} className='action primary'>
                {isAddingBudget ? 'Sending...' : `Send Invoice`}
              </div>
            ) : (
              <div onClick={confirmNewBudgetInvoice} className='action primary disabled'>
                {cannotAddBudgetReason}
              </div>
            )}
          </>
        ) : (
          <>
            <div onClick={props.closeBrandBudgetModal} className='action secondary'>
              Cancel
            </div>
            <div onClick={goToConfirmPanel} className={cn('action primary', { disabled: !newBudgetAmount })}>
              Next
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

BrandBudgetModal.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,

  closeBrandBudgetModal: PropTypes.func.isRequired,
  syncBrandBudget: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  closeBrandBudgetModal,
  syncBrandBudget
})(BrandBudgetModal);
