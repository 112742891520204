import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './ShopBuildingSettingsPanel.scss';

import { updateUserSettings } from '../../Actions/UserActions';
import { getSettings } from '../../Helpers/user_helpers';

import Checkbox from '../../Components/General/Checkbox';

const ShopBuildingSettingsPanel = props => {
  const settings = getSettings(props.user);
  const { addPinsToTopInCollections } = settings;
  return (
    <div className='settings-block shop-building-settings-container'>
      <div className='label'>Collection Settings</div>
      <div className='sublabel'>Customize your shop building experience across all the collections you create.</div>
      <div className='settings-group'>
        <div className='checkboxes'>
          <Checkbox
            selected={!addPinsToTopInCollections}
            onToggle={() => props.updateUserSettings({ addPinsToTopInCollections: false })}
            label='Add Links to Bottom of Collections'
          />
          <Checkbox
            selected={!!addPinsToTopInCollections}
            onToggle={() => props.updateUserSettings({ addPinsToTopInCollections: true })}
            label='Add Links to Top of Collections'
          />
        </div>
      </div>
    </div>
  );
};

ShopBuildingSettingsPanel.propTypes = {
  user: PropTypes.object.isRequired,
  updateUserSettings: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  updateUserSettings
})(ShopBuildingSettingsPanel);
