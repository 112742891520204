import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Report.scss';

import { isBrand } from '../../Helpers/user_helpers';
import { insertMetaTags } from '../../Helpers/seo_helpers';
import usePageLoadEvent from '../../CustomHooks/usePageLoadEvent';

import RequiresBrandLoginPanel from '../../Components/General/RequiresBrandLoginPanel';
import BrandReport from '../../Components/BrandReport/BrandReport';

const Report = props => {
  const { user } = props;

  // Eventing
  usePageLoadEvent('Report - View Page');

  if (!isBrand(user)) return <RequiresBrandLoginPanel />;

  return (
    <div className='report-outer-container'>
      {insertMetaTags({
        title: 'Program Report',
        description: '',
        image: ''
      })}
      <div className='report-inner-container'>
        <BrandReport />
      </div>
    </div>
  );
};

Report.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  // functions go here
})(Report);
