import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import './BulkTalentModalSidebarClearing.scss';

import { getBrandListsForTalentTab, sortBrandListsByRecentlyEdited } from '../../../Helpers/user_helpers';

const BulkTalentModalSidebarClearing = props => {
  const { user, selectedTalent, setSelectedTalent, updateStatusState } = props;

  // Sort the lists by most recently added
  const lists = getBrandListsForTalentTab(user);
  const sortedLists = sortBrandListsByRecentlyEdited(lists);

  const sectionGroups = [
    {
      display: 'Clear General',
      actions: [
        {
          display: 'Clear All',
          talent: selectedTalent
        },
        {
          display: 'With Existing Chats',
          talent: selectedTalent.filter(t => t.has_chatted)
        },
        {
          display: 'With Gifting Requests',
          talent: selectedTalent.filter(t => t.has_request)
        },
        {
          display: 'With Opportunity Requests',
          talent: selectedTalent.filter(t => t.has_opportunity_request)
        },
        {
          display: 'With Custom Rates',
          talent: selectedTalent.filter(t => t.has_rate)
        }
      ]
    },
    {
      display: 'Clear From Lists',
      actions: sortedLists.map(list => {
        const listUserIds = new Set(list.users.map(u => u.User_id));
        return {
          display: list.title,
          talent: selectedTalent.filter(t => listUserIds.has(t.id))
        };
      })
    }
  ].filter(group => group.actions.length);

  return (
    <div className='additional-actions-container'>
      <div className='clear-talent-hover'>
        Clear Talent <FontAwesomeIcon icon={faChevronDown} />
      </div>
      <div className='additional-actions-overlay'>
        <div className='additional-actions'>
          {sectionGroups.map(group => {
            return (
              <div key={group.display} className='additional-action-group'>
                <div className='group-title'>{group.display}</div>
                <div className='group-actions'>
                  {group.actions.map(action => {
                    const { talent, display } = action;
                    return (
                      <div
                        key={display}
                        onClick={() => {
                          if (!talent.length) return window.ALERT.warn('No creators to clear.');
                          const idsToClear = new Set(talent.map(t => t.id));
                          const talentToKeep = selectedTalent.filter(t => !idsToClear.has(t.id));
                          setSelectedTalent(talentToKeep);
                          updateStatusState({});
                          window.ALERT.success(`Removed ${talent.length} creator${talent.length === 1 ? '' : 's'}.`);
                        }}
                        className={cn('additional-action', { disabled: !talent.length })}
                      >
                        <div className='display'>{display}</div>
                        <div className='count'>{talent.length}</div>
                        <div className='clear-overlay'>Clear</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

BulkTalentModalSidebarClearing.propTypes = {
  user: PropTypes.object.isRequired,
  selectedTalent: PropTypes.array.isRequired,
  setSelectedTalent: PropTypes.func.isRequired,
  updateStatusState: PropTypes.func.isRequired
};

export default BulkTalentModalSidebarClearing;
