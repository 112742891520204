import React from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import './HomeHeaderCreators.scss';

import { openAuthModal } from '../../../Actions/UIActions';

const HomeHeaderCreators = props => {
  const applyNow = () => {
    props.openAuthModal('apply');
  };

  const seo_title = 'ShopMy | Monetize Your Influence';
  const seo_description = 'Enable your audience to shop your product recommendations while building meaningful partnerships with world-class brands.';
  const description = 'Enable your audience to shop your product recommendations while building meaningful partnerships with world-class brands.';

  return (
    <div className='home-header-creators-outer-container'>
      <MetaTags>
        <title>{seo_title}</title>
        <meta property='og:title' content={seo_title} />
        <meta property='description' content={seo_description} />
        <meta property='og:description' content={seo_description} />
        <meta property='og:image' content='https://static.shopmy.us/social-share-banner.png' />
      </MetaTags>
      <div className='home-header-creators-inner-container'>
        <div className='spacer' />
        <div className='main-container'>
          <h1 className='title'>
            Your taste, made
            <br />
            effortlessly shoppable.
          </h1>
          <h2 className='subtitle'>{description}</h2>
          <div className='actions'>
            <div onClick={applyNow} className='action primary'>
              Apply Now
            </div>
            <Link to='/home/brands' className='action secondary'>
              I'm a brand
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeHeaderCreators.propTypes = {
  user: PropTypes.object.isRequired,
  openAuthModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  openAuthModal
})(HomeHeaderCreators);
