import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import './BulkRequestModal.scss';
import { connect } from 'react-redux';
import cn from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import cogoToast from 'cogo-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faLocationArrow, faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';

import { sendLookbookOffPlatformInvitation, getBrandLookbookInvites } from '../../APIClient/lookbook_invites';
import { openArtistModal, closeRequestModal, openBrandBudgetModal } from '../../Actions/UIActions';
import { createSamplesRequest, createOpportunityRequest } from '../../Actions/AnalyticsActions';
import { updateBrandSettings } from '../../Actions/BrandActions';
import { createOpportunityPaymentTier } from '../../Actions/OpportunityActions';
import { getBrandRemaining } from '../../APIClient/brands';
import {
  isAdminControlMode,
  getbulkRequestModalStartingParams,
  getbulkRequestModalCloseCallback,
  getbulkRequestModalSubmitCallback,
  isArtistModalVisible
} from '../../Helpers/ui_helpers';
import { getBrand, getBrandLists, getBrandLookbooks, getOpportunities, getBrandBudgetRemaining } from '../../Helpers/user_helpers';
import { isSubscribedToFeature, blockOnRequiringSubscriptionToFeature } from '../../Helpers/subscription_helpers';
import { getDisplayForPaymentTier } from '../../Helpers/opportunity_helpers';
import { getPrettyNumber } from '../../Helpers/formatting';

import Modal from '../General/Modal';
import DateSelector from '../General/DateSelector';
import Select from '../General/Select';
import BulkRequestModalSearch from './BulkRequestModalSearch';
import BulkRequestModalSelectedUsers from './BulkRequestModalSelectedUsers';
import BulkRequestModalMessage from './BulkRequestModalMessage';

import Loader from '../Loader/Loader';
import Tooltip from '../General/Tooltip';
import ConfirmPrompt from '../General/ConfirmPrompt';
import { getLookbook } from '../../APIClient/lookbooks';
import { getOrderHandledByData } from '../../Helpers/lookbook_helpers';

const BulkRequestModal = props => {
  const { user, talent: brandTalent, createSamplesRequest, openArtistModal, ui, analytics } = props;
  const talent = brandTalent.talent;
  const brand = getBrand(user);
  const lists = getBrandLists(user);
  const lookbooks = getBrandLookbooks(user);
  const opportunities = getOpportunities(user);
  const requests = analytics?.brandAnalytics?.requests || [];
  const opportunity_requests = analytics?.brandAnalytics?.opportunity_requests || [];
  const startingParams = getbulkRequestModalStartingParams(ui);
  const closeCallback = getbulkRequestModalCloseCallback(ui);
  const submitCallback = getbulkRequestModalSubmitCallback(ui);
  const warningOnClose = startingParams.warningOnClose;

  // Allow two panel request types
  const requestType = startingParams.type || 'gifting'; // gifting or opportunities
  const isGiftingRequestType = requestType === 'gifting';
  const isOpportunityRequestType = requestType === 'opportunities';

  const mostRecentlySentGiftingRequest = _.orderBy(requests, 'createdAt', 'desc')[0];
  const mostRecentlySentOpportunityRequest = _.orderBy(opportunity_requests, 'createdAt', 'desc')[0];
  const mostRecentlySentRequest = isGiftingRequestType ? mostRecentlySentGiftingRequest : mostRecentlySentOpportunityRequest;

  // Gifting
  const [offPlatformLookbookInvites, setOffPlatformLookbookInvites] = useState([]);
  const [brandGiftingRemaining, setBrandGiftingRemaining] = useState(null);
  const [brandGiftingReupped, setBrandGiftingReupped] = useState(null);
  const generalGiftingRequestObject = { title: 'General Gifting Request' };
  const [selectedLookbookId, setSelectedLookbookId] = useState(startingParams.Lookbook_id || mostRecentlySentRequest?.Lookbook_id || null);
  const [selectedGiftingOption, setSelectedGiftingOption] = useState(null);
  const [isLoadingFullSelectedLookbook, setIsLoadingFullSelectedLookbook] = useState(true);
  const loadingSelectedLookbookTimeout = useRef(null);

  // Actions
  const close = () => {
    const completeClose = () => {
      props.closeRequestModal();
      closeCallback && closeCallback();
    };
    if (warningOnClose) {
      setForceOuter(false);
      confirmAlert({
        title: 'Just Confirming?',
        message: warningOnClose,
        buttons: [
          {
            label: 'Cancel',
            className: 'cancel',
            onClick: () => setForceOuter(true)
          },
          {
            label: 'Close Modal',
            onClick: completeClose
          }
        ]
      });
    } else {
      completeClose();
    }
  };

  const closeAfterSubmission = () => {
    props.closeRequestModal();
    submitCallback && submitCallback();
  };

  const finishLoadingSelectedLookbookWithTimeout = () => {
    if (loadingSelectedLookbookTimeout.current) clearTimeout(loadingSelectedLookbookTimeout.current);
    loadingSelectedLookbookTimeout.current = setTimeout(() => setIsLoadingFullSelectedLookbook(false), 500);
  };

  const loadFullSelectedLookbook = async lookbookId => {
    try {
      setIsLoadingFullSelectedLookbook(true);
      const queryData = {
        returnAllSiblings: true
      };

      const lookbookResponse = await getLookbook(lookbookId, queryData);
      const lookbook = lookbookResponse.lookbook;
      setSelectedGiftingOption(lookbook);
    } catch (err) {
      console.error({ err });
      cogoToast.error('Error loading selected lookbook, please try again');
      setSelectedGiftingOption(generalGiftingRequestObject);
    } finally {
      finishLoadingSelectedLookbookWithTimeout();
    }
  };

  useEffect(() => {
    if (!selectedLookbookId) {
      setSelectedGiftingOption(generalGiftingRequestObject);
      setIsLoadingFullSelectedLookbook(false);
    } else {
      loadFullSelectedLookbook(selectedLookbookId);
    }

    return () => {
      if (loadingSelectedLookbookTimeout.current) clearTimeout(loadingSelectedLookbookTimeout.current);
    };
  }, [selectedLookbookId]);

  // no loading or error state because we want this strictly in the background
  useEffect(() => {
    if (isGiftingRequestType) {
      getBrandLookbookInvites(brand.id)
        .then(resp => setOffPlatformLookbookInvites(resp.lookbookInvites))
        .catch(err => console.error({ err }));

      getBrandRemaining(user)
        .then(resp => {
          setBrandGiftingRemaining(Math.max(resp.gifting_remaining, 0));
          setBrandGiftingReupped(resp.gifting_requests_reupped);
        })
        .catch(err => console.error({ err }));
    }
  }, [requestType]);

  // Seraching Talent
  const [isFocused, setIsFocused] = useState(false);
  const [curSearchVal, setCurSearchVal] = useState('');
  const handleFocused = () => setIsFocused(true);
  const handleBlurred = e => {
    e.stopPropagation();
    setIsFocused(false);
    setCurSearchVal('');
  };

  const [platformUsersToAdd, setPlatformUsersToAdd] = useState(startingParams.preselectedUsers || []);
  const [emailUsersToAdd, setEmailUsersToAdd] = useState([]);
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);
  const [expiresOn, setExpiresOn] = useState(null);
  const [invalidLocationUserIds, setInvalidLocationUserIds] = useState([]);

  const selectedUserIdSet = new Set(platformUsersToAdd.map(u => u.id));
  const selectedEmailSet = new Set(emailUsersToAdd.map(u => u.email));
  const [selectedUsersApiState, setSelectedUsersApiState] = useState({});
  const selectedUsersApiStateRef = useRef({});

  const [isSendingRequests, setIsSendingRequests] = useState(false);
  const usersHaveBeenAdded = !!platformUsersToAdd.length || !!emailUsersToAdd.length;
  const someRequestsErrored = Object.values(selectedUsersApiState).some(s => s.error);
  const allRequestsSuccessful = Object.values(selectedUsersApiState).every(s => s.success);

  // Opportunities
  const hasMultiplePaymentTiersSelected = _.uniqBy(platformUsersToAdd, 'OpportunityPaymentTier_id').length > 1;
  const [selectedOpportunityId, setSelectedOpportunityId] = useState(startingParams.Opportunity_id || opportunities?.[0]?.id);
  const selectedOpportunity = opportunities?.find(o => o.id === selectedOpportunityId);
  const [selectedOpportunityPaymentTierId, setSelectedOpportunityPaymentTierId] = useState(
    hasMultiplePaymentTiersSelected ? null : startingParams.OpportunityPaymentTier_id || selectedOpportunity?.payment_tiers?.[0]?.id
  );
  const selectedOpportunityPaymentTier = selectedOpportunity?.payment_tiers?.find(t => t.id === selectedOpportunityPaymentTierId);
  const isSubmittingPlan = isOpportunityRequestType && hasMultiplePaymentTiersSelected;
  const hasPaymentTierReadyToGo = selectedOpportunityPaymentTier || hasMultiplePaymentTiersSelected;

  useEffect(() => {
    if (isGiftingRequestType) {
      if (selectedGiftingOption?.allowedCountryCodes) {
        const allowedCountryCodesArr = selectedGiftingOption.allowedCountryCodes.split(',');
        const invalidUserIds = platformUsersToAdd.filter(u => !allowedCountryCodesArr.includes(u.countryCode)).map(u => u.id);
        setInvalidLocationUserIds(invalidUserIds);
      } else {
        setInvalidLocationUserIds([]);
      }
    }
  }, [platformUsersToAdd, selectedGiftingOption, requestType]);

  // UI Handling so that we can show modals correctly stacked, since we can show the bulk gifting and artist modal at the same time
  const [forceOuter, setForceOuter] = useState(isArtistModalVisible(ui));

  const userIsAlreadySelected = user => {
    if (user.id && selectedUserIdSet.has(user.id)) return true;
    else if (user.email && selectedEmailSet.has(user.email)) return true;
    else return false;
  };

  const selectUsers = users => {
    const usersToAdd = users.filter(u => !userIsAlreadySelected(u));

    if (usersToAdd.length === 1) {
      const user = usersToAdd[0];

      if (!user.id) setEmailUsersToAdd([...emailUsersToAdd, user]);
      else setPlatformUsersToAdd([...platformUsersToAdd, user]);
    } else {
      setPlatformUsersToAdd([...platformUsersToAdd, ...usersToAdd]);
    }
  };

  const removeUser = user => {
    if (user.id) setPlatformUsersToAdd(platformUsersToAdd.filter(u => u.id !== user.id));
    else setEmailUsersToAdd(emailUsersToAdd.filter(u => u.email !== user.email));
  };

  // Gifting Actions
  const sendRequestsWithConfirmation = () => {
    const warningMessages = [];

    // Lookbook Case
    if (isGiftingRequestType && selectedGiftingOption?.id) {
      const orderHandledByData = getOrderHandledByData(user, selectedGiftingOption);

      if (orderHandledByData.handledBy === 'shopify' && orderHandledByData.isPartiallyConnected) {
        warningMessages.push(
          'This lookbook contains some items that are disconnected from your Shopify integration. Disconnected items will not be available for creators to select.'
        );
      }

      if (!selectedGiftingOption.price_limit && !selectedGiftingOption.item_limit) {
        warningMessages.push(
          'This lookbook does not have a price or item limit set. Creators will be able to select any number of items at any price.'
        );
      }
    }

    if (warningMessages.length) {
      setForceOuter(false);

      return confirmAlert({
        customUI: ({ onClose }) => (
          <ConfirmPrompt
            header='Are you sure?'
            subheader='Please review the following before sending out your requests:'
            subheaderElement={
              <div className='warning-message'>
                {warningMessages.map((warningMessage, i) => (
                  <div key={`warning-message-${i}`} className='warning-message-item' style={{ marginTop: '12px' }}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <span style={{ marginLeft: '8px' }}>{warningMessage}</span>
                  </div>
                ))}
              </div>
            }
            allowOverflow
            onCancel={() => {
              setForceOuter(isArtistModalVisible(ui));
              onClose();
            }}
            submitBtnDisplay={'Send'}
            onSubmit={sendRequests}
            hideFormFields={true}
          />
        )
      });
    }

    sendRequests();
  };

  const sendRequests = async () => {
    if (!usersHaveBeenAdded) return cogoToast.warn('Please add users to send requests to');
    else if (isSendingRequests) return cogoToast.warn('Please wait for the current requests to finish sending');
    else if (isGiftingRequestType && emailUsersToAdd.length && !selectedLookbookId)
      return cogoToast.error('You can only invite off platform users to a lookbook');

    /******************************************************** */
    // Block on missing subscriptions
    /******************************************************** */
    if (isOpportunityRequestType && blockOnRequiringSubscriptionToFeature('OPPORTUNITIES')) return null;
    if (isGiftingRequestType && blockOnRequiringSubscriptionToFeature('GIFTING')) return null;

    /******************************************************** */
    // Get users to send requests to
    /******************************************************** */
    const platformBulkRequestLimit = isGiftingRequestType
      ? isAdminControlMode(ui)
        ? 1e6
        : brandGiftingRemaining
      : isOpportunityRequestType
      ? 1e6
      : 0;

    // need to filter out anyone that has already successfully been sent a request
    const filteredPlatformUsersToAdd = platformUsersToAdd.filter(u => !selectedUsersApiStateRef.current[u.id]?.success);
    const reducedPlatformUsersToAdd = filteredPlatformUsersToAdd.slice(0, platformBulkRequestLimit);
    const filteredEmailUsersToAdd = emailUsersToAdd.filter(u => !selectedUsersApiStateRef.current[u.email]?.success);
    const allUsersToAdd = [...reducedPlatformUsersToAdd, ...filteredEmailUsersToAdd];

    if (platformBulkRequestLimit === 0) return cogoToast.warn('You have no requests remaining');
    else if (allUsersToAdd.length === 0 && allRequestsSuccessful) return cogoToast.warn('All requests have already been sent');
    setIsSendingRequests(true);

    /******************************************************** */
    // Send gifting requests
    /******************************************************** */

    for (const user of allUsersToAdd) updateUserApiStatus(user, true, null, null);
    const batchedRequests = _.chunk(allUsersToAdd, 3);
    for (const batch of batchedRequests) await Promise.all(batch.map(isGiftingRequestType ? sendGiftingRequest : sendOpportunityRequest));
    if (isGiftingRequestType) window.__ADD_EVENT__('Gifting - Send Gifting Request');
    else window.__ADD_EVENT__('Opportunities - Send Opportunity');

    /******************************************************** */
    // Handle messaging
    /******************************************************** */
    const updatedRequestState = { ...selectedUsersApiStateRef.current };
    let [successfulRequestCount, erroredRequestCount] = [0, 0];
    for (const user of allUsersToAdd) {
      const { id, email } = user;
      const { success, error } = updatedRequestState[id || email];
      if (success) ++successfulRequestCount;
      else if (error) ++erroredRequestCount;
    }

    if (!erroredRequestCount) {
      cogoToast.success(`All requests sent successfully`);
      closeAfterSubmission();
    } else if (!successfulRequestCount) cogoToast.error(`No requests were sent`);
    else cogoToast.warn(`Sent ${successfulRequestCount} requests, ${erroredRequestCount} ${erroredRequestCount === 1 ? 'failed' : 'failed'}`);

    setBrandGiftingRemaining(Math.max(brandGiftingRemaining - successfulRequestCount, 0));
    setIsSendingRequests(false);
  };

  const sendGiftingRequest = user => {
    return new Promise(resolve => {
      const requestData = _.omitBy({ brandMessage: message, Lookbook_id: selectedLookbookId, expiresOn, files }, _.isNil);
      user.id
        ? createSamplesRequest(user, requestData)
            .then(resp => (resp?.request ? updateUserApiStatus(user, false, true, null) : updateUserApiStatus(user, false, false, true)))
            .catch(resp => updateUserApiStatus(user, false, false, true))
            .finally(() => resolve())
        : sendLookbookOffPlatformInvitation({ ...requestData, email: user.email })
            .then(() => updateUserApiStatus(user, false, true, null))
            .catch(err => {
              updateUserApiStatus(user, false, false, true, err);
            })
            .finally(() => resolve());
    });
  };

  // Opportunity Actions
  const sendOpportunityRequest = async user => {
    const requestData = {
      Opportunity_id: selectedOpportunity.id,
      OpportunityPaymentTier_id: user?.OpportunityPaymentTier_id || selectedOpportunityPaymentTier.id,
      User_id: user.id,
      Brand_id: brand.id,
      ...(expiresOn ? { expiresOn } : {}),
      ...(message ? { brandMessage: message } : {})
    };
    try {
      const resp = await props.createOpportunityRequest(requestData);
      if (resp?.request) updateUserApiStatus(user, false, true, null);
      else updateUserApiStatus(user, false, false, true);
    } catch (error) {
      updateUserApiStatus(user, false, false, true);
    }
  };

  // overrides scoping allowing us to update the state of each user as we send requests
  const updateUserApiStatus = (user, loading, success, error, message) => {
    const currentApiState = { ...selectedUsersApiStateRef.current };

    if (loading || success || error) currentApiState[user.id || user.email] = { loading, success, error, message };
    else delete currentApiState[user.id || user.email];

    setSelectedUsersApiState(currentApiState);
    selectedUsersApiStateRef.current = currentApiState;
  };

  const removeInvalidLocationUsers = () => {
    const filteredPlatformUsersToAdd = platformUsersToAdd.filter(u => !invalidLocationUserIds.includes(u.id));
    setPlatformUsersToAdd(filteredPlatformUsersToAdd);
  };

  const findIfUserAlreadyReceivedRequestOfType = user => {
    if (isGiftingRequestType) {
      const filterForNoLookbookSelected = r => !r.Lookbook_id && r.User_id === user.id;
      const filterForLookbookSelected = r => r.Lookbook_id && r.User_id === user.id && r.Lookbook_id === selectedLookbookId;
      const userHasRequest = requests.some(selectedLookbookId ? filterForLookbookSelected : filterForNoLookbookSelected);

      const userHasOffPlatformRequest = offPlatformLookbookInvites.some(
        invite => invite.email === curSearchVal && selectedLookbookId === invite.Lookbook_id
      );

      const userHasRequestOfType = userHasRequest || userHasOffPlatformRequest;
      return userHasRequestOfType;
    } else if (isOpportunityRequestType) {
      const alreadyHasRequest = opportunity_requests.some(r => r.Opportunity_id === selectedOpportunity.id && r.User_id === user.id);
      return alreadyHasRequest;
    }
    return true;
  };

  const openBudgetModal = () => props.openBrandBudgetModal({});

  const allowedCountryCodesArr = selectedGiftingOption?.allowedCountryCodes?.split(',') || [];

  const somePlatformUsersHaveAlreadyReceivedRequestType = platformUsersToAdd.some(findIfUserAlreadyReceivedRequestOfType);
  const someOffPlatformUsersHaveAlreadyReceivedRequestType = emailUsersToAdd.some(findIfUserAlreadyReceivedRequestOfType);
  const someUsersHaveAlreadyReceivedRequestType =
    somePlatformUsersHaveAlreadyReceivedRequestType || someOffPlatformUsersHaveAlreadyReceivedRequestType;

  let canSend, totalToSend, numberOverLimit, cannotSendMessage, warningMessage;
  if (isGiftingRequestType) {
    totalToSend = platformUsersToAdd.filter(u => !findIfUserAlreadyReceivedRequestOfType(u)).length + emailUsersToAdd.length;
    numberOverLimit = totalToSend - brandGiftingRemaining;
    canSend = totalToSend && totalToSend <= brandGiftingRemaining && !isLoadingFullSelectedLookbook;
    if (!totalToSend) cannotSendMessage = 'Please select talent';
    if (brandGiftingRemaining < totalToSend)
      cannotSendMessage = brandGiftingRemaining === 0 ? 'Out of Requests' : `${numberOverLimit} Over Sending Limit`;
  } else if (isOpportunityRequestType) {
    totalToSend = platformUsersToAdd.length + emailUsersToAdd.length;
    let costOfSending, remainingBudgetTooLowForEvenSendingOne, maxBudgetTooLowForEvenSendingOne;
    const maxOpportunityBudget = selectedOpportunity?.maxBudget;
    const budgetRemaining = getBrandBudgetRemaining(user);

    if (isSubmittingPlan) {
      costOfSending = _.sumBy(platformUsersToAdd, u => {
        const payment_tier = selectedOpportunity.payment_tiers.find(t => t.id === u.OpportunityPaymentTier_id);
        return payment_tier?.fixedFee || 0;
      });
      canSend = totalToSend > 0;
    }

    if (!isSubmittingPlan) {
      canSend = !!totalToSend && selectedOpportunity && selectedOpportunityPaymentTier;
      if (!totalToSend) cannotSendMessage = cannotSendMessage || 'Please select talent';
      if (!selectedOpportunity) cannotSendMessage = cannotSendMessage || 'Please select an opportunity';
      if (!hasPaymentTierReadyToGo) cannotSendMessage = cannotSendMessage || 'Please select a payment tier';
      costOfSending = selectedOpportunityPaymentTier?.fixedFee * totalToSend || 0;
      remainingBudgetTooLowForEvenSendingOne = selectedOpportunityPaymentTier?.fixedFee > budgetRemaining;
      maxBudgetTooLowForEvenSendingOne = maxOpportunityBudget && selectedOpportunityPaymentTier?.fixedFee > maxOpportunityBudget;
    }

    const remainingBudgetTooLowForEveryoneToAccept = costOfSending > budgetRemaining;
    const maxBudgetTooLowForEveryoneToAccept = maxOpportunityBudget && costOfSending > maxOpportunityBudget;

    const increaseBudgetDisclaimerSpan = (
      <span className='clickable' onClick={openBudgetModal}>
        increase your budget
      </span>
    );
    if (maxBudgetTooLowForEvenSendingOne) {
      canSend = false;
      cannotSendMessage = `Budget Too Low`;
      warningMessage = (
        <span>
          This exceeds your max budget of $${getPrettyNumber(maxOpportunityBudget)}. Please select a lower payment tier or{' '}
          {increaseBudgetDisclaimerSpan}.`
        </span>
      );
    } else if (maxBudgetTooLowForEveryoneToAccept) {
      canSend = true; // Still allow sending
      warningMessage = `You are about to send ${totalToSend} opportunities at a potential total cost of $${getPrettyNumber(
        costOfSending
      )}. This is over the maximum budget you set of $${getPrettyNumber(
        maxOpportunityBudget
      )}. Creators will not be able to accept these opportunities once your max budget has been hit.`;
    } else if (remainingBudgetTooLowForEvenSendingOne) {
      canSend = false;
      cannotSendMessage = `Budget too low`;
      warningMessage = budgetRemaining ? (
        <span>
          Your active budget is at ${getPrettyNumber(budgetRemaining) || 0} which is too low to send this opportunity. Please{' '}
          {increaseBudgetDisclaimerSpan} to continue.
        </span>
      ) : (
        <span>You have no active budget to send this opportunity. Please {increaseBudgetDisclaimerSpan} to continue.</span>
      );
    } else if (remainingBudgetTooLowForEveryoneToAccept) {
      canSend = true; // Still allow sending
      warningMessage = (
        <span>
          You are about to send {totalToSend} opportunities at a total cost of ${commaNumber(costOfSending)}. This is over your remaining budget of $
          {commaNumber(budgetRemaining)}. Creators will not be able to accept once your budget has run out. Please {increaseBudgetDisclaimerSpan} to
          ensure all can accept!
        </span>
      );
    }
  }

  return (
    <Modal
      visible={true}
      forceOuter={forceOuter}
      close={close}
      className='bulk-request-modal-outer'
      innerClassName='bulk-request-modal-inner'
      showClose={true}
    >
      <div className='bulk-request-modal'>
        <div className='bulk-request-title-container'>
          <div className='bulk-request-title'>
            {isSubmittingPlan ? 'Submit Plan' : isGiftingRequestType ? 'Send Gifting Requests' : 'Send Opportunity'}
          </div>
          {isGiftingRequestType && (
            <div className='gifting-remaining-container'>
              <div className='gifting-remaining-label'>Requests Remaining</div>
              {!_.isNil(brandGiftingRemaining) ? (
                <Tooltip
                  message={
                    brandGiftingRemaining < 1e4
                      ? isSubscribedToFeature(user, 'GIFTING_LITE')
                        ? `You will get 25 new requests on ${brandGiftingReupped ||
                            'the first day of the month'}. To upgrade to recieve more gifting, please go to your subscriptions page.`
                        : `You will get 50 new requests on ${brandGiftingReupped ||
                            'the first day of the month'}. To upgrade to unlimited gifting, please go to your subscriptions page.`
                      : 'You are currently on the unlimited gifting package.'
                  }
                  getIconDiv={() => <span className='gifting-remaining'>{brandGiftingRemaining > 1e4 ? 'Unlimited' : brandGiftingRemaining}</span>}
                />
              ) : (
                <Loader size={30} />
              )}
            </div>
          )}
        </div>

        {/* <OutsideClickHandler onOutsideClick={handleBlurred}> */}
        <div className='bulk-request-section'>
          {!isSubmittingPlan && (
            <div className='bulk-request-section-title'>
              Who do you want to send it to?
              {someUsersHaveAlreadyReceivedRequestType && (
                <div className='bulk-request-section-subtitle'>Greyed out users have already received a request for this gifting option</div>
              )}
            </div>
          )}
          {(!!platformUsersToAdd.length || !!emailUsersToAdd.length) && (
            <BulkRequestModalSelectedUsers
              invalidLocationUserIds={invalidLocationUserIds}
              platformUsersToAdd={platformUsersToAdd}
              emailUsersToAdd={emailUsersToAdd}
              removeUser={removeUser}
              selectedUsersApiState={selectedUsersApiState}
              openArtistModal={openArtistModal}
              findIfUserAlreadyReceivedRequestOfType={findIfUserAlreadyReceivedRequestOfType}
            />
          )}

          {!isSubmittingPlan && (
            <BulkRequestModalSearch
              brand={brand}
              lists={lists}
              talent={talent}
              selectUsers={selectUsers}
              removeUser={removeUser}
              selectedLookbookId={selectedLookbookId}
              selectedUserIdSet={selectedUserIdSet}
              selectedEmailSet={selectedEmailSet}
              openArtistModal={openArtistModal}
              handleBlurred={handleBlurred}
              handleFocused={handleFocused}
              isFocused={isFocused}
              curSearchVal={curSearchVal}
              setCurSearchVal={setCurSearchVal}
              findIfUserAlreadyReceivedRequestOfType={findIfUserAlreadyReceivedRequestOfType}
              isGiftingRequestType={isGiftingRequestType}
              isOpportunityRequestType={isOpportunityRequestType}
            />
          )}
        </div>
        <div className='bulk-request-section'>
          {!isSubmittingPlan && (
            <div className='bulk-request-section-title'>
              What are you sending?
              {isGiftingRequestType
                ? !!lookbooks?.length && (
                    <span className='bulk-request-section-title-disclaimer'>
                      {lookbooks?.length} active {lookbooks?.length === 1 ? 'lookbook' : 'lookbooks'}
                    </span>
                  )
                : !!opportunities?.length && (
                    <span className='bulk-request-section-title-disclaimer'>
                      {opportunities?.length} active {opportunities?.length === 1 ? 'opportunity' : 'opportunities'}
                    </span>
                  )}
            </div>
          )}
          {!isSubmittingPlan && (
            <div className='bulk-request-subsection'>
              <div className='select-request-options'>
                {isGiftingRequestType ? (
                  <Select
                    noStyling
                    options={[
                      ..._.orderBy(lookbooks, 'createdAt', 'desc').map(lookbook => ({
                        value: lookbook.id,
                        label: lookbook.title,
                        sublabel: `Created on ${moment(lookbook.createdAt).format('MMM Do, YYYY')}`
                      })),
                      {
                        value: 'general',
                        label: 'General Gifting Request'
                      }
                    ]}
                    value={selectedLookbookId || 'general'}
                    isDisabled={isLoadingFullSelectedLookbook}
                    isLoading={isLoadingFullSelectedLookbook}
                    onChangeValue={value => setSelectedLookbookId(value === 'general' ? null : value)}
                  />
                ) : (
                  <>
                    <Select
                      noStyling
                      options={_.orderBy(opportunities, 'createdAt', 'desc').map(opportunity => ({
                        value: opportunity.id,
                        label: opportunity.title,
                        sublabel: `Created on ${moment(opportunity.createdAt).format('MMM Do, YYYY')}`
                      }))}
                      value={selectedOpportunity?.id}
                      onChangeValue={opportunity => {
                        const newOpportunity = opportunities.find(o => o.id === opportunity);
                        const newPaymentTier = newOpportunity.payment_tiers[0];
                        setSelectedOpportunityId(newOpportunity.id);
                        setSelectedOpportunityPaymentTierId(newPaymentTier.id);
                      }}
                    />

                    {selectedOpportunity && (
                      <div className='subsection-with-label'>
                        <div className='select-label small'>
                          What Payment Tier? Your remaining budget is{' '}
                          <span className='clickable' onClick={openBudgetModal}>
                            ${getPrettyNumber(getBrandBudgetRemaining(user) || 0)}.
                          </span>
                        </div>
                        <Select
                          noStyling
                          options={[
                            ..._.orderBy(selectedOpportunity.payment_tiers, 'fixedFee').map(tier => ({
                              value: tier.id,
                              label: getDisplayForPaymentTier(tier)
                            })),
                            {
                              value: null,
                              label: 'Create New Tier'
                            }
                          ]}
                          value={selectedOpportunityPaymentTier?.id}
                          onChangeValue={async tier => {
                            if (tier) {
                              setSelectedOpportunityPaymentTierId(selectedOpportunity.payment_tiers.find(t => t.id === tier)?.id);
                            } else {
                              confirmAlert({
                                customUI: ({ onClose }) => (
                                  <ConfirmPrompt
                                    header='Specify Payment Tier'
                                    subheader='What would you like to offer for this opportunity?'
                                    allowOverflow
                                    onCancel={onClose}
                                    customInputFields={[
                                      {
                                        display: 'Fixed Fee',
                                        isSingleLine: true,
                                        placeholder: '$1,000',
                                        value: 'fixedFee'
                                      },
                                      {
                                        display: 'Adjusted Commission Rate',
                                        isSingleLine: true,
                                        placeholder: '20% (Optional)',
                                        value: 'adjCommissionRate'
                                      }
                                    ]}
                                    onSubmit={async data => {
                                      const { fixedFee, adjCommissionRate } = data;
                                      if (!fixedFee && !adjCommissionRate) return;
                                      const resp = await props.createOpportunityPaymentTier({
                                        Opportunity_id: selectedOpportunity.id,
                                        fixedFee: fixedFee ? +fixedFee.replace(/\D/g, '') : null,
                                        adjCommissionRate: adjCommissionRate ? +adjCommissionRate.replace(/\D/g, '') : null
                                      });
                                      setSelectedOpportunityPaymentTierId(resp.payment_tier.id);
                                    }}
                                  />
                                )
                              });
                            }
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              {!selectedGiftingOption?.id && isGiftingRequestType && !isLoadingFullSelectedLookbook && (
                <span className='bulk-request-section-title-disclaimer'>
                  Gifting requests without lookbooks require you to manually generate the order on your POS system and place the order tracking number
                  in here to alert the creator the package has been sent
                </span>
              )}
            </div>
          )}
        </div>
        {!!selectedGiftingOption && isGiftingRequestType && (
          <div className={cn('bulk-request-location-specific', { active: selectedGiftingOption.allowedCountryCodes })}>
            <FontAwesomeIcon icon={faLocationArrow} />
            <span>
              {selectedGiftingOption.allowedCountryCodes ? `This is a ${allowedCountryCodesArr.join(', ')} Only Lookbook` : 'No Country Restrictions'}
            </span>
            <Tooltip
              message={`To configure which addresses are valid for this Lookbook please navigate to Lookbook settings`}
              getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle} />}
            />
          </div>
        )}
        <div className='bulk-request-section'>
          <div className='bulk-request-section-title'>Add a message</div>
          <div className='bulk-request-message-container'>
            <BulkRequestModalMessage
              user={user}
              message={message}
              setMessage={setMessage}
              updateBrandSettings={props.updateBrandSettings}
              isGiftingRequestType={isGiftingRequestType}
              isOpportunityRequestType={isOpportunityRequestType}
              files={files}
              setFiles={setFiles}
            />
          </div>
        </div>
        <div className='bulk-request-section'>
          <div className='bulk-request-section-title'>
            Set a Response Deadline
            <div className='bulk-request-section-subtitle'>
              After this date creators will no longer be able to accept
              {isGiftingRequestType ? ' this gifting request' : ' this opportunity'}.
            </div>
          </div>
          <div className='bulk-request-set-expiration-container'>
            <DateSelector placeholder='Select Date' isSingleDate date={expiresOn} onDateChange={date => setExpiresOn(date)} />
            {expiresOn ? (
              <div className='clear-expiration' onClick={() => setExpiresOn(null)}>
                Clear Expiration
              </div>
            ) : (
              mostRecentlySentRequest?.expiresOn && (
                <div className='set-expiration-from-last-selected' onClick={() => setExpiresOn(moment(mostRecentlySentRequest.expiresOn))}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  Set to {moment(mostRecentlySentRequest.expiresOn).format('MMM Do')}
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <div className='bulk-request-footer'>
        <div className='disclaimer-container'>
          {warningMessage && (
            <div className='disclaimer warning'>
              <FontAwesomeIcon icon={faExclamationTriangle} />
              {warningMessage}
            </div>
          )}
        </div>
        <div
          className={cn('bulk-request-footer-buttons', {
            isDisabled: !usersHaveBeenAdded || isSendingRequests || allRequestsSuccessful
          })}
        >
          <button className='bulk-request-footer-button' onClick={close}>
            Cancel
          </button>
          {invalidLocationUserIds.length > 0 && (
            <button className='bulk-request-footer-button warn-button' onClick={removeInvalidLocationUsers}>
              {`Remove Creators Outside ${allowedCountryCodesArr.slice(0, 2).join(', ')}${allowedCountryCodesArr.length > 2 ? '...' : ''} (${
                invalidLocationUserIds.length
              })`}
            </button>
          )}
          <button className={cn('bulk-request-footer-button send-requests-button', { inactive: !canSend })} onClick={sendRequestsWithConfirmation}>
            {cannotSendMessage
              ? cannotSendMessage
              : someRequestsErrored
              ? 'Try Again'
              : isOpportunityRequestType
              ? `Send ${totalToSend > 1 ? `${totalToSend} Opportunities` : 'Opportunity'}`
              : `Send ${totalToSend > 1 ? `${totalToSend} Requests` : 'Request'}`}
          </button>
        </div>
      </div>
    </Modal>
  );
};

BulkRequestModal.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  closeRequestModal: PropTypes.func.isRequired,
  createSamplesRequest: PropTypes.func.isRequired,
  createOpportunityRequest: PropTypes.func.isRequired,
  createOpportunityPaymentTier: PropTypes.func.isRequired,
  updateBrandSettings: PropTypes.func.isRequired,
  openBrandBudgetModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui, talent, analytics } = state;
  return { user, ui, talent, analytics };
};

export default connect(mapStateToProps, {
  createSamplesRequest,
  createOpportunityRequest,
  closeRequestModal,
  openArtistModal,
  updateBrandSettings,
  createOpportunityPaymentTier,
  openBrandBudgetModal
})(BulkRequestModal);
