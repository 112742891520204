import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import './RecommendationResults.scss';

import { createSamplesRequest } from '../../../Actions/AnalyticsActions';
import { openRequestModal } from '../../../Actions/UIActions';

import RecommendationResult from './RecommendationResult';

const RecommendationResults = props => {
  const { recommendations, user, ui, createSamplesRequest, isLoadingRecommendations, onRemoveRecommendation, onSendGifting } = props;
  const isInitialLoad = !recommendations.length && isLoadingRecommendations;
  const isRefreshingRecommendations = isLoadingRecommendations && !isInitialLoad;

  useEffect(() => {
    window.__ADD_EVENT__('Gifting - Recommendations');
  }, []);

  return (
    <div className={cn('recommendation-results-container', { loading: isRefreshingRecommendations })}>
      {isInitialLoad ? (
        _.range(5).map(idx => <div className='recommendation-results-skeleton' key={idx} />)
      ) : recommendations.length ? (
        recommendations.map(recommendation => (
          <RecommendationResult
            key={recommendation.User_id}
            user={user}
            ui={ui}
            recommendation={recommendation}
            createSamplesRequest={createSamplesRequest}
            isRefreshingRecommendations={isRefreshingRecommendations}
            onRemoveRecommendation={onRemoveRecommendation}
            onSendGifting={onSendGifting}
            openRequestModal={props.openRequestModal}
          />
        ))
      ) : (
        <div className='empty-results'>No recommendations yet. Check again soon!</div>
      )}
    </div>
  );
};

RecommendationResults.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  createSamplesRequest: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  onRemoveRecommendation: PropTypes.func.isRequired,
  onSendGifting: PropTypes.func.isRequired,
  recommendations: PropTypes.array.isRequired,
  isLoadingRecommendations: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  createSamplesRequest,
  openRequestModal
})(RecommendationResults);
