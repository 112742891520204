import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import './LandingApplyPanel.scss';

import Loader from '../../Components/Loader/Loader';
import ForgotPassword from '../../Components/Auth/ForgotPassword';

import { US_STATES, PRO_LICENSE_TYPES } from '../../Helpers/constants';
import { getRootSMSUrl } from '../../Helpers/helpers';
import { validateUsername } from '../../Helpers/user_helpers';

import { addEvent } from '../../APIClient/events';
import { useCodePreRegistration } from '../../APIClient/users';

class LandingApplyPanel extends Component {
  static propTypes = {
    setPanelRef: PropTypes.func.isRequired,
    panel: PropTypes.number.isRequired,
    updatePanel: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { location, history } = this.props;

    if (location?.hash && location.hash.toUpperCase() === location.hash) {
      this.setState({ code: location.hash.slice(1) });
      this.props.updatePanel(0);
      const newUrl = location.pathname + (location.search || '');
      history.replace(newUrl);
    }
  }

  state = {
    email: ``,
    password: '',
    pitch: '',
    name: '',
    username: ``,
    code: '',
    licenseNumber: '',
    licenseState: '',
    licenseType: '',
    isSubmitting: false,
    hasApplied: false,
    isResettingPassword: false
  };

  handleLogin = event => {
    const { loginUser } = this.props;
    const { email, password, isSubmitting } = this.state;

    event.preventDefault();

    if (!email) return window.ALERT.error('Email Field cannot be left blank.');
    if (!password) return window.ALERT.error('Password Field cannot be left blank.');
    if (isSubmitting) return;

    this.setState({ isSubmitting: true });
    loginUser({ email, password })
      .then(
        ({ user }) => {
          if (user) {
            this.props.history.push('/');
            window.scrollTo(0, 0);
            addEvent(`Pro - Logged In`);
          }
        },
        err => {}
      )
      .finally(() => {
        this.setState({ isSubmitting: false });
      });
  };

  handleRegister = async event => {
    /*
      This code is used twice, once here and once in the LandingPromotionPanel component. If
      we have a third use of it, refactor this.

      If you find a bug here, please make sure to fix it in the LandingPromotionPanel as well.
    */

    const { registerUser } = this.props;
    const { email, password, name, username, code, licenseNumber, licenseType, licenseState, isSubmitting } = this.state;

    event.preventDefault();
    if (isSubmitting) return;
    if (!name) return window.ALERT.error('Name field cannot be left blank.');
    if (!email) return window.ALERT.error('Email field cannot be left blank.');
    if (!password) return window.ALERT.error('Password field cannot be left blank.');
    if (!code) return window.ALERT.error('Invitation code field cannot be left blank.');

    if (window.__IS_PRO__) {
      if (!licenseType) return window.ALERT.error('Please select the type of license.');
      if (!licenseNumber || licenseNumber?.length < 2) return window.ALERT.error('Please enter a valid license number.');
      if (!licenseState) return window.ALERT.error('Please select the state where your license is issued.');
    }

    const validUsername = validateUsername(username);
    if (!validUsername) return;

    this.setState({ isSubmitting: true });

    try {
      const useCodeResp = await useCodePreRegistration({ email, username, code });
      if (useCodeResp.success) {
        try {
          const registrationResp = await registerUser({ email, password, username, name, code, licenseState, licenseType, licenseNumber });
          if (registrationResp.success) {
            window.scrollTo(0, 0);
            this.props.history.push('/');
            addEvent(`Pro - Successfully Registered`, { code, email });
          } else {
            addEvent(`Pro - Error Registering`, {
              error: registrationResp ? JSON.stringify(registrationResp) : 'Unknown'
            });
          }
        } catch (error) {
          console.error(error);
          window.ALERT.error('There was an issue with registration. Please try again.');
        }
      } else {
        window.ALERT.error(_.get(useCodeResp, 'error', 'There was an issue with this code. Please try again.'));
        addEvent(`Pro - Error Registering`, {
          error: useCodeResp ? JSON.stringify(useCodeResp) + ` Code: ${code}, Email: ${email}` : 'Unknown'
        });
      }
    } catch (error) {
      console.error(error);
      window.ALERT.error('There was an issue with this code. Please try again.');
    }
    this.setState({ isSubmitting: false });
  };

  handleApply = event => {
    const { applyToWaitlist } = this.props;
    const { name, email, pitch, socialhandles, isApplying } = this.state;
    event.preventDefault();
    if (isApplying) return;
    if (!name) {
      window.ALERT.error('Please provide your name.');
      return;
    }
    if (!email) {
      window.ALERT.error('Please provide an email to reach you at.');
      return;
    }
    if (!socialhandles) {
      window.ALERT.error('Please add at least one social handle.');
      return;
    }
    if (!pitch) {
      window.ALERT.error('Please explain a bit about who you are.');
      return;
    }

    const fullPitch = `${pitch} || ${socialhandles}`;
    this.setState({ isSubmitting: true });
    applyToWaitlist({ email, name, pitch: fullPitch }).then(
      ({ data }) => {
        const { success, error } = data || {};
        if (success) {
          this.setState({ isSubmitting: false, hasApplied: true });
          addEvent(`Pro - Submitted Application`, { email });
        } else {
          window.ALERT.error(error);
          this.setState({ isSubmitting: false });
        }
      },
      err => {
        this.setState({ isSubmitting: false });
      }
    );
  };
  updateUsername = username => {
    const formattedUsername = username.toLowerCase().replace(/[^A-Z a-z0-9]/, '');
    this.setState({ username: formattedUsername });
  };

  render() {
    const { panel, updatePanel, loginUser } = this.props;
    const { isSubmitting, hasApplied, email, password, name, username, pitch, licenseNumber, socialhandles, isResettingPassword } = this.state;

    const isSigningUp = panel === 0;
    const isLoggingIn = panel === 1;
    const isApplying = panel === 2;
    return (
      <div ref={this.props.setPanelRef} className='landing-apply-panel-outer-container'>
        <div className='landing-apply-panel-container'>
          <div className='apply-panel-explainer-container'>
            <div className='explainer'>Register with your professional license today!</div>
          </div>
          <div className='landing-login-container'>
            <div className='auth-container'>
              {isResettingPassword ? (
                <ForgotPassword loginUser={loginUser} />
              ) : (
                <>
                  <div className='register-options'>
                    <div
                      onClick={() => updatePanel(0)}
                      className={classnames('register-option', {
                        selected: panel === 0
                      })}
                    >
                      Sign Up
                    </div>
                    <div
                      onClick={() => updatePanel(1)}
                      className={classnames('register-option', {
                        selected: panel === 1
                      })}
                    >
                      Log In
                    </div>
                  </div>
                  {hasApplied && isApplying ? (
                    <div className='application-success'>
                      Thank you for applying! We will review and reach out to you soon with an invitation code if everything looks good. If you would
                      like to add more details to your application, please email our team at <a href='mailto:team@shopmy.us'>team@shopmy.us</a>.
                    </div>
                  ) : (
                    <form onSubmit={panel === 0 ? this.handleRegister : panel === 1 ? this.handleLogin : this.handleApply}>
                      {(isSigningUp || isApplying) && (
                        <input
                          type='text'
                          className='name-input standard-input'
                          placeholder='Full Name'
                          onChange={({ target }) => this.setState({ name: target.value })}
                          value={name}
                        />
                      )}
                      <input
                        type='email'
                        className='email-input standard-input'
                        placeholder='Email Address'
                        onChange={({ target }) => this.setState({ email: target.value })}
                        value={email}
                      />
                      {(isLoggingIn || isSigningUp) && (
                        <input
                          type='password'
                          className='password-input standard-input'
                          placeholder='Password'
                          onChange={({ target }) => this.setState({ password: target.value })}
                          value={password}
                        />
                      )}
                      {isApplying && (
                        <textarea
                          id='pitch'
                          name='pitch'
                          type='text'
                          className='standard-input'
                          value={pitch}
                          rows='5'
                          placeholder='Briefly describe who you are and the types of content you promote regularly on your social channels.'
                          onChange={({ target }) => this.setState({ pitch: target.value })}
                        />
                      )}
                      {isSigningUp && (
                        <input
                          type='text'
                          className='username-input standard-input'
                          placeholder='Username'
                          onChange={({ target }) => this.updateUsername(target.value)}
                          value={username}
                        />
                      )}
                      {isApplying && (
                        <textarea
                          id='socialhandles'
                          name='socialhandles'
                          type='text'
                          value={socialhandles}
                          className='standard-input'
                          rows='3'
                          placeholder='Links to your social channels (Instagram, YouTube, TikTok, etc.)'
                          onChange={({ target }) => this.setState({ socialhandles: target.value })}
                        />
                      )}
                      {isSigningUp ? (
                        <>
                          <Select
                            placeholder='License Type'
                            className='license-select'
                            options={PRO_LICENSE_TYPES}
                            onChange={({ value, label }) => this.setState({ licenseType: value })}
                          />
                          <input
                            type='text'
                            className='code-input standard-input'
                            placeholder='License Number'
                            onChange={({ target }) => this.setState({ licenseNumber: target.value })}
                            value={licenseNumber}
                          />
                          <Select
                            placeholder='State of License'
                            className='license-select'
                            options={US_STATES.map(({ abbreviation, name }) => ({ value: abbreviation, label: name }))}
                            onChange={({ value, label }) => this.setState({ licenseState: value })}
                          />
                        </>
                      ) : null}
                      <div className='login-btn-container'>
                        <div
                          onClick={panel === 0 ? this.handleRegister : panel === 1 ? this.handleLogin : this.handleApply}
                          className={classnames('login-btn', {
                            loading: isSubmitting
                          })}
                        >
                          {isSubmitting && <Loader size={44} />}
                          {panel === 0 ? 'CREATE ACCOUNT' : panel === 1 ? 'LOG IN' : 'Apply'}
                        </div>
                      </div>
                      {isLoggingIn && (
                        <div onClick={() => this.setState({ isResettingPassword: true })} className='forgot-password'>
                          Forgot Password
                        </div>
                      )}
                      {isSigningUp && (
                        <div className='upsell-to-pro'>
                          Not a licensed esthetician, cosmetologist or other professional? Apply for our ShopMy network{' '}
                          <a href={`${getRootSMSUrl()}/home?auth=apply`} target='blank' rel='noopener noreferrer'>
                            here
                          </a>
                          .
                        </div>
                      )}
                    </form>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LandingApplyPanel);
