import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import cn from 'classnames';
import _ from 'lodash';
import './InviteTalentModal.scss';

import { inviteTalent } from '../../APIClient/brands';

import { getRootSMSUrl, matchScrollHeight } from '../../Helpers/helpers';
import { getSlugifiedRegistrationCode } from '../../Helpers/brand_helpers';
import { getBrandName, getBrand, getUsername, getEmail, getBrandRegistrationCode } from '../../Helpers/user_helpers';
import { isValidEmail } from '../../Helpers/formatting';

import Modal from '../General/Modal';

const InviteTalentModal = props => {
  const { user } = props;
  const [curValue, setCurValue] = useState('');
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    window.__ADD_EVENT__('General - Invite Talent', { brand: getBrand(user) });
  }, []);

  const emails = _.uniq(
    curValue
      .split(',')
      .join(' ')
      .split('\n')
      .join(' ')
      .split(' ')
      .filter(isValidEmail)
  );

  const registrationCode = getBrandRegistrationCode(user);
  const brandRegistrationCode = registrationCode?.code || getSlugifiedRegistrationCode(getBrandName(user));

  const promotePageUrlRoot = `${getRootSMSUrl()}/promote/${getUsername(user)}`;
  const promotePageUrl = `${promotePageUrlRoot}#${brandRegistrationCode}`;
  const goToPromotePage = () => window.open(promotePageUrl, '_blank');
  const goToPromotePageRoot = () => window.open(promotePageUrlRoot, '_blank');

  const sendEmails = async () => {
    if (!emails.length) return cogoToast.warn(`Please enter at least one valid email address.`);
    setIsSending(true);
    inviteTalent(user, emails)
      .then(resp => {
        const { successfully_sent, already_found } = resp;
        const alreadyFoundMsg = !already_found.length
          ? ''
          : already_found.length === 1
          ? `${already_found[0].name} (${already_found[0].email}) is already registered on the platform.`
          : `${already_found.map(u => `${u.name} (${u.email})`).join(', ')} are already registered on the platform.`;
        if (successfully_sent.length && !already_found.length) {
          cogoToast.success(`Successfully sent ${successfully_sent.length} invite${successfully_sent.length === 1 ? '' : 's'}!`);
        } else if (successfully_sent.length && already_found.length) {
          cogoToast.success(
            `Successfully sent ${successfully_sent.length} invite${successfully_sent.length === 1 ? '' : 's'}!${
              alreadyFoundMsg ? ` ${alreadyFoundMsg}` : ''
            }`,
            { hideAfter: 6 }
          );
        } else if (!successfully_sent.length && already_found.length) {
          cogoToast.success(alreadyFoundMsg);
        }
      })
      .catch(error => {})
      .finally(() => {
        setIsSending(false);
        props.closeModal();
      });
  };

  const brandName = getBrandName(user);
  const brandEmail = getEmail(user);
  return (
    <Modal visible close={props.closeModal} innerClassName='invite-talent-modal-outer-container' showClose isNestedModal>
      <div className='header-container'>
        <div className='header'>Invite Talent</div>
        <div className='subheader'>
          Do you have talent you want to join your affiliate program that are not currently on the platform?
          <br />
          <br />
          Enter in a list of emails below, and we will send an email to them with instructions on how to sign up for the platform. When they join
          through your custom landing page and sign up code, they’ll automatically show in your dashboard and you’ll be able to give them custom
          commission rates and assign discount codes.
          <br />
          <br />
          The automated email at the bottom of this form is what the talent will receive and all responses to this email will be sent to {brandEmail}.
          <br />
          <br />
          If you’d like to craft and send your own email, here is your landing page and sign-up code for creators:
          <br />
          <br />
          <div className='page-and-code'>
            <div className='page'>
              Sign up page:{' '}
              <span onClick={goToPromotePageRoot} className='link'>
                {promotePageUrlRoot}
              </span>
            </div>
            <div className='code'>
              Invitation Code: <b>{brandRegistrationCode}</b>
            </div>
          </div>
        </div>
      </div>
      <div className='body-container'>
        <div className='example-email'>
          <div className='subject-header'>Subject: Collaborate with {brandName}</div>
          <br />
          Hi there,
          <br />
          <br />
          We’re excited to introduce to you a new way for you to easily earn by recommending {brandName} products. Our new affiliate program allows
          you to:
          <ul>
            <li>Easily create affiliate links to individual {brandName} products and earn commission</li>
            <li>Receive custom commissionable discount codes for your audience</li>
            <li>Build and share lists of your favorite {brandName} products</li>
          </ul>
          To join our affiliate program, please sign up{' '}
          <span onClick={goToPromotePage} className='link'>
            here
          </span>
          {` `}
          using the invitation code <b>{brandRegistrationCode}</b>
          .
          <br />
          <br />
          Thanks and if you have any questions, don't hesitate to ask!
          <br />
          <br />
          Best,
          <br />
          The {brandName} team
        </div>
        <div className='form'>
          <textarea
            className='text-input'
            placeholder='Enter a comma separated list of emails you want to invite.'
            onChange={({ target }) => setCurValue(target.value)}
            rows={6}
            ref={ref => matchScrollHeight(ref)}
            value={curValue}
          />
          <div className={cn('send-btn', { active: emails.length })} onClick={sendEmails}>
            {emails.length ? (
              <>
                {isSending ? 'Sending ' : 'Send '}
                {emails.length} invite{emails.length === 1 ? '' : 's'}
                {isSending ? '...' : ''}
              </>
            ) : (
              'Send Emails'
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

InviteTalentModal.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  // functions go here
})(InviteTalentModal);
