import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';
import Select from 'react-select';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './ShopifyIntegrationPanel.scss';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import { testShopifyIntegration as testShopifyIntegrationAPI } from '../../APIClient/integrations';
import { saveShopifyIntegration, updateBrandIntegration, updateBrandInventoryLocations } from '../../Actions/BrandActions';
import { getBrand, getAllShopifyIntegrations } from '../../Helpers/user_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import SelectOption from '../General/SelectOption';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfiniteScrollSelect from '../General/InfiniteScrollSelect';
import { getShopifyLocations } from '../../APIClient/shopify';
import TagTypeInput from '../General/TagTypeInput';
import CheckboxButton from '../General/Buttons/CheckboxButton';
import { SHOPIFY_INVENTORY_BEHAVIOR_OPTIONS } from './Constants/ShopifySettings';

const ShopifyIntegrationPanel = props => {
  const { user, ui, updateBrandInventoryLocations } = props;
  const brand = getBrand(user);
  const shopifyIntegrations = getAllShopifyIntegrations(user);
  const integrationsById = _.keyBy(shopifyIntegrations, 'id');
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(shopifyIntegrations[0]?.id || null);
  const [showShopifySetup, setShowShopifySetup] = useState(false);
  // shopify store location selector state
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  const [lastCursor, setLastCursor] = useState(null);
  const [locationsHasNextPage, setLocationsHasNextPage] = useState(true);

  const selectedIntegration = integrationsById[selectedIntegrationId];
  const shopifySiblingReductionConfig = JSON.parse(selectedIntegration?.shopifySiblingReductionConfig || '{}'); // prettier-ignore

  const [shopName, setShopName] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [adminAccessToken, setAdminAccessToken] = useState('');

  const [isTestingShopify, setIsTestingShopify] = useState(false);
  const [shopifyTestSuccess, setShopifyTestSuccess] = useState(false);

  const saveShopifyIntegration = async () => {
    const data = { shopName, privateKey, adminAccessToken };

    if (!shopName || !privateKey || !adminAccessToken) return cogoToast.error('Please fill out all fields.');
    else if (!shopifyTestSuccess) return cogoToast.error('Please test your Shopify connection before saving.');

    props
      .saveShopifyIntegration(brand, data)
      .then(() => cogoToast.success('Successfully saved Shopify config.'))
      .catch(() => cogoToast.error('There was an error saving your Shopify config.'));
  };

  const testShopifyIntegration = async e => {
    // don't seed any of these values with values from props because backend function will use the database values if null
    const Brand_id = brand.id;

    if (!shopName?.length || !privateKey?.length || !adminAccessToken?.length) return cogoToast.error('Please fill out all fields.');
    const data = { Brand_id, shopName, privateKey, adminAccessToken };

    setIsTestingShopify(true);
    testShopifyIntegrationAPI(data)
      .then(() => {
        cogoToast.success('Successful! Please press save.');
        setShopifyTestSuccess(true);
      })
      .catch(e => {
        cogoToast.error(e || 'There was an error connecting to Shopify, please check your credentials and try again.');
      })
      .finally(() => {
        setIsTestingShopify(false);
      });
  };

  const updateShopifyIntegration = variable => {
    const orderCreationIsOn = _.get(selectedIntegration, 'allowOrderCreation');

    const changingWillDisableAutomatedLookbookShopifyHandling = orderCreationIsOn && variable === 'allowOrderCreation';

    if (changingWillDisableAutomatedLookbookShopifyHandling) {
      return confirmAlert({
        title: 'Just confirming',
        message: `By turning off order creation, you will no longer be able to use automated lookbook fulfillment. Are you sure you want to continue?`,
        buttons: [
          { label: 'No', onClick: () => {}, className: 'cancel' },
          {
            label: 'Yes',
            onClick: () => {
              props.updateBrandIntegration(selectedIntegration.id, { [variable]: !_.get(selectedIntegration, variable) });
            }
          }
        ]
      });
    } else {
      props.updateBrandIntegration(selectedIntegration.id, { [variable]: !_.get(selectedIntegration, variable) });
    }
  };

  useEffect(() => {
    loadSelectedLocations();
  }, [selectedIntegrationId]);

  const loadSelectedLocations = async () => {
    if (!selectedIntegrationId) return;

    const integration = integrationsById[selectedIntegrationId];
    const inventoryLocations = integration?.inventory_locations || [];

    if (!inventoryLocations.length) {
      setSelectedLocations([]);
      return;
    }

    try {
      setIsLoadingLocations(true);
      const data = {
        BrandIntegration_id: selectedIntegrationId,
        locationIds: inventoryLocations.map(l => l.shopifyLocationId)
      };

      const response = await getShopifyLocations(data);

      const formatedLocations = response.locations.map(l => ({
        label: l.name,
        value: +l.id.split('/').pop()
      }));

      setSelectedLocations(formatedLocations);
    } catch (err) {
      console.error(err);
      cogoToast.error('There was an error loading your selected locations, please try again.');
    } finally {
      setIsLoadingLocations(false);
    }
  };

  const loadMoreLocations = async (searchTerm, isNewSearch = false) => {
    if (!locationsHasNextPage && !isNewSearch) return [];

    const data = {
      BrandIntegration_id: selectedIntegrationId
    };

    if (searchTerm) {
      data.searchTerm = searchTerm;
    }

    if (!isNewSearch && lastCursor) {
      data.lastCursor = lastCursor;
    }

    let response;
    try {
      setIsLoadingLocations(true);
      response = await getShopifyLocations(data);
    } catch (err) {
      console.error(err);
      cogoToast.error('There was an error loading your store locations, please try again.');
      setIsLoadingLocations(false);
      return [];
    }

    const locations = response.locations?.edges || [];
    const hasNextPage = response.locations?.pageInfo?.hasNextPage || false;
    const formattedLocations = locations.map(lEdge => ({
      label: lEdge.node.name,
      value: +lEdge.node.id.split('/').pop(),
      subLabel: `${lEdge.node.address.city}, ${lEdge.node.address.province}, ${lEdge.node.address.country}`
    }));

    if (hasNextPage) {
      const lastCollection = _.last(locations);
      setLastCursor(lastCollection?.cursor || null);
    }

    setLocationsHasNextPage(hasNextPage);
    setIsLoadingLocations(false);
    return formattedLocations;
  };

  const updateLocationsOnBlur = async () => {
    const locationIds = selectedLocations?.map(l => l.value) || [];
    const integration = integrationsById[selectedIntegrationId];
    const existingInventoryLocations = integration?.inventory_locations || [];
    const existingIds = existingInventoryLocations.map(l => l.shopifyLocationId);

    const needsUpdate = !(locationIds?.length === existingIds?.length && locationIds.every(id => existingIds.includes(id)));

    if (!needsUpdate) return;

    await updateBrandInventoryLocations(selectedIntegrationId, locationIds);
  };

  const updateShopifySiblingReductionConfig = variable => {
    const newConfig = { ...shopifySiblingReductionConfig, [variable]: !shopifySiblingReductionConfig[variable] };
    props.updateBrandIntegration(selectedIntegration.id, { shopifySiblingReductionConfig: JSON.stringify(newConfig) });
  };

  const updateShopfiyCustomOrderTags = newTags => {
    props.updateBrandIntegration(selectedIntegration.id, { shopifyOrderCustomTags: newTags.join(',') || null });
  };

  const updateShopifyOrderInventoryBehavior = option => {
    props.updateBrandIntegration(selectedIntegration.id, { shopifyOrderInventoryBehavior: option.value });
  };

  const getManageIntegrationSettings = () => {
    return [
      {
        display: 'Allow Order Creation',
        description: 'Allow ShopMy to create orders in your Shopify store. This is required for automatic order fulfillment in Lookbooks.',
        variable: 'allowOrderCreation'
      },
      {
        display: 'Allow Catalog Syncing',
        description: 'Allow ShopMy direct access to your public Shopify catalog. This is required for automatic order fulfillment in Lookbooks.',
        variable: 'allowProductSyncing',
        secondaryCheckboxes: isAdminControlMode(ui)
          ? [
              {
                display: 'Ignore Prices on Sibling Merge',
                description: 'Ignore prices when grouping variations into sibling groups.',
                active: shopifySiblingReductionConfig['ignorePricesOnMerge'],
                variable: 'ignorePricesOnMerge',
                updateFnOverride: updateShopifySiblingReductionConfig
              },
              {
                display: 'Sibling Information in Title',
                description:
                  'Siblings exist on different PDPs and can be identified with the sibling information in the title and a clear, distinct separator. Examples: "Title in Red" and "Title in Blue" or "Title - Red" and "Title - Blue"',
                active: shopifySiblingReductionConfig['siblingInformationInTitle'],
                variable: 'siblingInformationInTitle',
                updateFnOverride: updateShopifySiblingReductionConfig
              },
              {
                display: 'Sibling Information in URL',
                description:
                  'Siblings can only be discerend from differences in the handle or URL, example: "bonne-nuit-white-navy" and "bonne-nuit-pale-blue-red" (https://lalignenyc.com/collections/sleepwear/products/bonne-nuit-white-navy)',
                active: shopifySiblingReductionConfig['siblingInformationInUrl'],
                variable: 'siblingInformationInUrl',
                updateFnOverride: updateShopifySiblingReductionConfig
              },
              {
                display: 'Sibling Information in Numerical Sku (Rare)',
                description:
                  'Siblings exist on different PDPs and can be identified with the numerical part of the Variant SKU. Examples: "1234BLUE" and "1234RED" or "SK1234-BLUE" and "SK1234-RED"',
                active: shopifySiblingReductionConfig['groupBySubSkusNumericalOnly'],
                variable: 'groupBySubSkusNumericalOnly',
                updateFnOverride: updateShopifySiblingReductionConfig
              },
              {
                display: 'Ignore Sibling Option 1',
                description: 'First option should be ignored when grouping variations into sibling groups.',
                active: shopifySiblingReductionConfig['ignoreOption1'],
                variable: 'ignoreOption1',
                updateFnOverride: updateShopifySiblingReductionConfig
              },
              {
                display: 'Ignore Sibling Option 2',
                description: 'Second option should be ignored when grouping variations into sibling groups.',
                active: shopifySiblingReductionConfig['ignoreOption2'],
                variable: 'ignoreOption2',
                updateFnOverride: updateShopifySiblingReductionConfig
              },
              {
                display: 'Ignore Sibling Option 3',
                description: 'Third option should be ignored when grouping variations into sibling groups.',
                active: shopifySiblingReductionConfig['ignoreOption3'],
                variable: 'ignoreOption3',
                updateFnOverride: updateShopifySiblingReductionConfig
              },
              {
                display: 'Has Multiple Brands',
                description: 'If the site is a retailer that carries multiple brands, this will disable the retailer name override.',
                active: shopifySiblingReductionConfig['hasMultipleBrands'],
                variable: 'hasMultipleBrands',
                updateFnOverride: updateShopifySiblingReductionConfig
              },
              {
                display: 'Override Inventory Quantity for Non Tracked Products',
                description:
                  "If a shopify product's inventory_management = null (inventory not tracked) AND inventory_quantity <= 0, this will override availability and allow those products always be ordered through lookbooks.",
                active: shopifySiblingReductionConfig['overrideInventoryQuantity'],
                variable: 'overrideInventoryQuantity',
                updateFnOverride: updateShopifySiblingReductionConfig
              },
              {
                display: 'Remove Variant Id From Sibling Urls',
                description:
                  'If the brand\'s site is headless, there is a good chance they do not want the variant id appended to the end of of sibling urls. This will remove the "variant" from the end of the url.',
                active: shopifySiblingReductionConfig['removeVariantIdFromSiblingUrls'],
                variable: 'removeVariantIdFromSiblingUrls',
                updateFnOverride: updateShopifySiblingReductionConfig
              }
            ]
          : []
      },
      {
        display: 'Allow Custom Codes',
        description:
          'ShopMy will sync your discount codes so that any update or creation you make in Shopify will be reflected in ShopMy. You can also create custom codes in ShopMy that will be automatically udpated in Shopify.',
        variable: 'allowCustomCodes'
      },
      {
        display: 'Allow Affiliate Tracking',
        description:
          'You must be on a package higher than Shopify Basic to use this feature. ShopMy will sync affiliate sales directly to your Shopify store so we always have the most up to date information on your sales.',
        variable: 'allowAffiliateTracking'
      },
      {
        display: 'Enable Custom Inventory Locations',
        description: 'Optionally, choose specific inventory locations to determine your product availability.',
        variable: 'allowInventoryLocations',
        customComponent: () => {
          if (!_.get(selectedIntegration, 'allowInventoryLocations')) return null;

          return (
            <div className='location-info-container'>
              <div className='description'>
                We use shopify to determine product availability if catalog syncing is enabled above. You may want to limit the inventory locations
                that we use to determine product availability. You can do this below by selecting one or more store or warehouse locations. Otherwise,
                we will use all locations.
              </div>
              <div className='location-select'>
                <InfiniteScrollSelect
                  isMultiple={true}
                  closeMenuOnSelect={false}
                  onSelectOptions={selected => {
                    setSelectedLocations(selected);
                  }}
                  handleBlur={updateLocationsOnBlur}
                  value={selectedLocations}
                  loadMoreOptions={loadMoreLocations}
                  menuHeight={'170px'}
                  isLoading={isLoadingLocations}
                  placeholder={'Optional inventory locations...'}
                />
              </div>
            </div>
          );
        }
      }
    ];
  };

  const getShopifyOrderSettings = () => {
    return [
      {
        display: 'Set Lookbook Shopify Line Items to $0',
        description:
          'By default, we set line item prices in Shopify Lookbook orders to true item prices, and then apply an order-level discount of 100%. This is to ensure that the order subtotal is accurate, and to allow you to report on the true value of gifted items. Depending on your accounting practices, you may want us to set Shopify line item prices for Lookbook gifting to $0 instead. Check this box to enable this behavior. *Note: For international orders, we must still set line item prices to a non-zero value to comply with international tax laws and customs regulations.',
        variable: 'allowZeroedOutLineItems'
      },
      {
        display: 'Use Creator Name as Order Customer',
        description:
          "By default, we use 'ShopMy, Inc.' as the customer name for Shopify orders. Check this box to use the creator's name for the customer field instead. *Note: This will only apply to orders placed after this setting is enabled.",
        variable: 'allowShopifyOrderUserAsCustomer',
        isHidden: !isAdminControlMode(ui)
      },
      {
        display: 'Require Creator Phone Number',
        description:
          'Check this box to require creators to provide a phone number when placing Shopify orders. This phone number will then be attached to Shopify orders.',
        variable: 'requireOrderPhoneNumber',
        isHidden: !isAdminControlMode(ui)
      },
      {
        display: 'Use Shopify Draft Order Flow for Lookbooks',
        description:
          'When enabled, we will create and complete draft orders in Shopify for Lookbook orders instead of creating orders directly. This is beneficial for some Shopify setups that utilize Shopify Flow or other automated tools to make order adjustments before finalizing orders.',
        variable: 'allowShopifyDraftOrderCompletion'
      },
      {
        display: 'Custom Order Tags',
        description:
          "Add custom Shopfiy tags used for Lookbook orders placed through ShopMy. By default, we add a 'ShopMy' tag to all Lookbook orders. Use comma or enter keys below to add a tag.",
        customComponent: () => {
          return (
            <div className='custom-order-tags-container'>
              <TagTypeInput
                tags={(selectedIntegration?.shopifyOrderCustomTags || '').split(',').filter(Boolean)}
                restrictedTags={['ShopMy']}
                handleSaveOnBlur={updateShopfiyCustomOrderTags}
                maxTagLength={40}
                showDescription={false}
              />
            </div>
          );
        }
      },
      {
        display: 'Specify Order Inventory Behavior',
        description: 'Choose how ShopMy should handle Shopify item inventory when placing Shopify Lookbook orders.',
        isHidden: !isAdminControlMode(ui),
        customComponent: () => {
          return (
            <Select
              options={SHOPIFY_INVENTORY_BEHAVIOR_OPTIONS}
              value={SHOPIFY_INVENTORY_BEHAVIOR_OPTIONS.find(o => o.value === selectedIntegration?.shopifyOrderInventoryBehavior)}
              onChange={updateShopifyOrderInventoryBehavior}
              components={{ Option: SelectOption }}
              isClearable={false}
              styles={{
                singleValue: provided => ({
                  ...provided,
                  fontWeight: 'bold',
                  fontSize: '14px'
                })
              }}
            />
          );
        }
      }
    ];
  };

  const hasMultipleShopifyIntegrations = shopifyIntegrations.length > 1;

  const SHOPIFY_INTEGRATION_SETTING_SECTIONS = [
    {
      label: `Integration Settings`,
      settings: getManageIntegrationSettings()
    },
    {
      label: 'Shopify Order Settings',
      settings: getShopifyOrderSettings()
    }
  ];

  return (
    <div className='shopify-integration-panel-outer'>
      <div className='shopify-integration-panel-inner'>
        {shopifyIntegrations.length > 0 && (
          <>
            {hasMultipleShopifyIntegrations ? (
              <div className='integration-select-container'>
                <div className='description'>
                  You have multiple Shopify integrations. Select one to manage it. If you want to add a new Shopify integration, please contact your
                  account manager.
                </div>
                <div className='shopify-integration-select-field'>
                  <Select
                    options={shopifyIntegrations.map(i => ({ value: i.id, label: i.shopName, sublabel: i.domain }))}
                    value={{ value: selectedIntegrationId, label: selectedIntegration.shopName }}
                    onChange={selected => setSelectedIntegrationId(selected.value)}
                    components={{ Option: SelectOption }}
                    styles={{
                      singleValue: provided => ({
                        ...provided,
                        fontWeight: 'bold',
                        fontSize: '14px'
                      })
                    }}
                  />
                </div>
              </div>
            ) : null}
            <div className='setting-sections'>
              {SHOPIFY_INTEGRATION_SETTING_SECTIONS.map(({ label, settings }) => (
                <div key={label}>
                  <div className='sublabel'>{label}</div>
                  <div className='checkboxes'>
                    {settings.map(({ display, description, variable, updateFnOverride, secondaryCheckboxes, customComponent, isHidden }) => {
                      if (isHidden) return null;
                      const currentValue = _.get(selectedIntegration, variable);

                      return variable ? (
                        <div key={display} className='setting-container'>
                          <CheckboxButton
                            label={display}
                            description={description}
                            isChecked={!!currentValue}
                            onChange={() => (updateFnOverride || updateShopifyIntegration)(variable)}
                          />
                          {!!secondaryCheckboxes?.length && (
                            <div className='secondary-checkboxes'>
                              {secondaryCheckboxes.map(({ display, description, variable, updateFnOverride, active }) => {
                                return (
                                  <div key={variable}>
                                    <CheckboxButton
                                      label={display}
                                      description={description}
                                      isChecked={!!active}
                                      onChange={() => (updateFnOverride || updateShopifyIntegration)(variable)}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          {!!customComponent && customComponent()}
                        </div>
                      ) : (
                        <div key={display} className='setting-container'>
                          <div className='setting-label'>{display}</div>
                          <div className='setting-description'>{description}</div>
                          {!!customComponent && customComponent()}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {!shopifyIntegrations.length && (
          <>
            <div className='label clickable' onClick={() => setShowShopifySetup(!showShopifySetup)}>
              <span style={{ marginRight: '1rem' }}>
                {showShopifySetup ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />}
              </span>
              Connect Your Shopify
            </div>
            {showShopifySetup ? (
              <div>
                <div className='settings-block' style={{ marginTop: '12px' }}>
                  <div className='label'>Shop Name</div>
                  <div className='sublabel'>
                    You can find this anytime in your app settings channel. Go to the "Apps and sales channel." The name should be there in the
                    format: YOUR_SHOP_NAME.myshopify.com.
                  </div>
                  <input placeholder='shop-my-app' type='text' onChange={e => setShopName(e.target.value)} value={shopName} />
                </div>
                <div className='settings-block'>
                  <div className='label'>API secret key</div>
                  <div className='sublabel'>The private API key for your Shopify app. (In API credentials section of the app settings panel.)</div>
                  <input
                    placeholder='db18c89f48196f20675fb23a075d029d'
                    type='text'
                    onChange={e => setPrivateKey(e.target.value)}
                    value={privateKey}
                  />
                </div>
                <div className='settings-block'>
                  <div className='label'>Admin API access token</div>
                  <div className='sublabel'>
                    The admin access key for your Shopify app. (If you are unable to see it, create a new app and copy the newly generated one.)
                  </div>
                  <input
                    placeholder='shpat_b7d27f85a3f4170139aa1ba4f307d04'
                    type='text'
                    onChange={e => setAdminAccessToken(e.target.value)}
                    value={adminAccessToken}
                  />
                </div>
                <div className='buttons'>
                  <button onClick={testShopifyIntegration} className='update-btn' type='button'>
                    {isTestingShopify ? 'Testing...' : 'Test'}
                  </button>
                  <button onClick={saveShopifyIntegration} className='btn' type='button'>
                    Save
                  </button>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

ShopifyIntegrationPanel.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  saveShopifyIntegration,
  updateBrandIntegration,
  updateBrandInventoryLocations
})(ShopifyIntegrationPanel);
