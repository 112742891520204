import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import './NotificationSettingsPanel.scss';

import { updateUserSettings } from '../../Actions/UserActions';
import { getSettings, hasMobileApp } from '../../Helpers/user_helpers';

import Select from '../../Components/General/Select';
import Tooltip from '../../Components/General/Tooltip';

const NotificationSettingsPanel = props => {
  const settings = getSettings(props.user);
  const hasApp = hasMobileApp(props.user);

  const optionGroups = [
    {
      title: 'Messages from Brands',
      options: [
        {
          display: 'General Messages',
          subdisplay: 'Wider updates or announcements from brands.',
          setting: 'notifyOnGeneralMessages'
        },
        {
          display: 'Direct Messages',
          subdisplay: 'Personalized messages sent directly to you from brands.',
          isRecommended: true,
          setting: 'notifyOnDirectMessages'
        }
      ]
    },
    {
      title: 'Gifting Requests',
      options: [
        {
          display: 'New Gifting Requests',
          subdisplay: 'Get notified when a brand sends you a gifting request.',
          isRecommended: true,
          setting: 'notifyOnNewGiftingRequests'
        },
        {
          display: 'Updates to Gifting Requests',
          subdisplay: 'Get notified about changes to gifting requests such as delivery status.',
          setting: 'notifyOnUpdatedGiftingRequests'
        }
      ]
    },
    {
      title: 'Opportunities',
      options: [
        {
          display: 'New Opportunities',
          subdisplay: 'Get notified when a brand sends a new opportunity.',
          isRecommended: true,
          setting: 'notifyOnNewOpportunities'
        },
        {
          display: 'Updates to Opportunities',
          subdisplay: 'Get notified when you complete your opportunity and payment has been transferred.',
          setting: 'notifyOnUpdatedOpportunities'
        }
      ]
    },
    {
      title: 'Discount Codes',
      options: [
        {
          display: 'New Discount Codes',
          subdisplay: 'Get notifications when a brand has sent you a new discount code.',
          isRecommended: true,
          setting: 'notifyOnNewDiscountCodes'
        },
        {
          display: 'Updates to Discount Codes',
          subdisplay: 'Get notifications when a brand has disabled your discount code.',
          isRecommended: true,
          setting: 'notifyOnUpdatedDiscountCodes'
        }
      ]
    }
  ];

  return (
    <div className='settings-block notification-settings-container'>
      <div className='label'>Notification Settings</div>
      <div className='sublabel'>
        Customize the emails and push notifications that you receive.
        {!hasApp ? ' Download the app to receive push notifications on your mobile device.' : ''}
      </div>
      <div className='option-groups'>
        {optionGroups.map(group => {
          const { title, options } = group;
          return (
            <div key={title} className='option-group'>
              <div className='option-group-title'>{title}</div>
              <div className='options'>
                {options.map(option => {
                  const { display, subdisplay, setting, isRecommended } = option;
                  const changeSelection = newValue => props.updateUserSettings({ [setting]: newValue });

                  const value = settings[setting];
                  const isDisabled = value === 'none';
                  const options = [
                    { value: 'push,email', label: 'Email & Mobile Notifications' },
                    { value: 'push', label: 'Mobile Notification Only' },
                    { value: 'email', label: 'Email Only' },
                    { value: 'none', label: 'Do Not Notify' }
                  ];

                  const additionalClasses = { disabled: isDisabled };
                  return (
                    <div key={display} className={cn('option', additionalClasses)}>
                      <div className='main'>
                        <div className={cn('display', additionalClasses)}>{display}</div>
                        {subdisplay && <div className={cn('subdisplay', additionalClasses)}>{subdisplay}</div>}
                      </div>
                      <div className={cn('select-container', additionalClasses)}>
                        {isDisabled && isRecommended && (
                          <Tooltip
                            className='recommended-alert'
                            message='We do not recommend disabling these notifications as it can have a negative impact on your ranking in brand results.'
                          >
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          </Tooltip>
                        )}
                        <Select
                          noStyling
                          options={options}
                          disableSearch
                          value={value}
                          placeholder='Select an option'
                          onChangeValue={newValue => changeSelection(newValue)}
                          isSearching={false}
                          isDisabled={false}
                          isLoading={false}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

NotificationSettingsPanel.propTypes = {
  user: PropTypes.object.isRequired,
  updateUserSettings: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  updateUserSettings
})(NotificationSettingsPanel);
